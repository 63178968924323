<template>
  <div class="container-page bg-app">
    <!-- Dialog -->
    <v-dialog v-model="dialog" persistent width="700">
      <v-card>
        <v-card-title class="primary justify-center white--text">
          KEY
        </v-card-title>

        <v-card-text>
          <v-container class="mt-4 px-0" fluid>
            <v-text-field outlined dense readonly label="Callback Endpoint" v-model="keyCallBack" />
            <v-text-field outlined dense readonly label="API Key" v-model="keyAPIKey" />
            <v-text-field outlined dense readonly label="Client ID" v-model="keyClientID" />
            <v-text-field outlined dense readonly hide-details label="Client Secret" v-model="keyClientSecret" />
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="primaryDarken1" class="primaryDarken1--text" @click="closeDialogKey()">
            ปิด
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Page title -->
    <base-page-header title="ลงทะเบียนแอปพลิเคชันเพื่อใช้งาน ThaID"
      subtitle="หน่วยงานภายนอกลงทะเบียนแอปพลิเคชันเพื่อขอใช้งาน ThaID"></base-page-header>

    <!-- Form -->
    <div class="form-wrapper" :class="$vuetify.breakpoint.xs ? 'mt-5' : ''">
      <v-form ref="entryForm" v-model="isValidForm" lazy-validation>
        <v-row class="mt-5">
          <v-col cols="12" md="11">
            <!-- Organize Type -->
            <v-row d-flex justify="start" align="start">
              <v-col cols="6" md="3">
                <p>ประเภทของหน่วยงาน :</p>
              </v-col>
              <v-col cols="6" md="3">
                <p>{{ userOrganization.organizeType }}</p>
              </v-col>
            </v-row>

            <!-- Application Type -->
            <v-row d-flex justify="start" align="start">
              <v-col cols="6" md="3" class="mt-4">
                <p>ประเภทแอปพลิเคชัน :</p>
              </v-col>
              <v-col cols="6" md="6">
                <v-radio-group row v-model="formRole">
                  <v-radio label="RP" value="1"></v-radio>
                  <!-- <v-radio label="AS" value="2"></v-radio>
                  <v-radio label="RP และ AS" value="3"></v-radio> -->
                </v-radio-group>
              </v-col>
            </v-row>

            <!-- Section 1: Organize Info -->
            <v-row d-flex justify="start" align="start">
              <v-col cols="6" md="3">
                <p>ข้อมูลแอปพลิเคชัน :</p>
              </v-col>
              <v-col cols="12" md="9">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field outlined dense v-model="appName" class="mb-5" :rules="field100Rules" counter="100"
                      @focus="checkAppNameUnique()">
                      <template v-slot:label>
                        <span>ชื่อแอปพลิเคชันภาษาไทย</span>
                        <span class="input-label"> *</span>
                      </template>
                    </v-text-field>

                    <v-text-field outlined dense v-model="appEnName" class="mb-5" :rules="field50Rules" counter="50"
                      hint="ต้องเป็นตัวพิมพ์เล็กทั้งหมด" @focus="unfocusAppEnName = false" :loading="unfocusAppEnName"
                      :color="appEnNameIsUnique ? 'success' : ''" :append-icon="appEnNameIsUnique ? 'mdi-checkbox-marked-circle' : ''
                        ">
                      <template v-slot:label>
                        <span>ชื่อแอปพลิเคชันภาษาอังกฤษ</span>
                        <span class="input-label"> *</span>
                      </template>
                    </v-text-field>

                    <!-- <v-text-field
                      outlined
                      dense
                      v-model="appAbbreviate"
                      :rules="field5_10Rules"
                      counter="10"
                      hint="ไม่สามารถเปลี่ยนแปลงได้ (5-10 ตัวอักษร)"
                      @focus="unfocusAppAbbreviate = false"
                      :loading="unfocusAppAbbreviate"
                      :color="appAbbreviateIsUnique ? 'success' : ''"
                      :append-icon="
                        appAbbreviateIsUnique
                          ? 'mdi-checkbox-marked-circle'
                          : ''
                      "
                    >
                      <template v-slot:label>
                        <span>ชื่อย่อแอปพลิเคชัน</span>
                        <span class="input-label"> *</span>
                      </template>
                    </v-text-field> -->
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-textarea outlined dense v-model="purpose" :rules="field255Rules" counter="255" rows="7"
                      @focus="checkAppNameUnique()">
                      <template v-slot:label>
                        <span>วัตถุประสงค์</span>
                        <span class="input-label"> *</span>
                      </template>
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider></v-divider>

            <!-- Section 2: Important Info -->
            <v-row class="mt-5" d-flex justify="start" align="start">
              <v-col cols="6" md="3">
                <p>ข้อมูลสำคัญ :</p>
              </v-col>
              <v-col cols="12" md="9">
                <v-row>
                  <v-col cols="12" md="6" xs="12" v-if="formRole != '2'">
                    <v-text-field outlined dense v-model="callbackUrl" :rules="field300Rules" counter="300"
                      placeholder="example.com" hint="https://example.com/" @focus="checkAppNameUnique()">
                      <template v-slot:label>
                        <span>Callback</span>
                        <span class="input-label"> *</span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" xs="12">
                    <v-combobox outlined dense multiple small-chips hide-selected v-model="model" :items="items"
                      :hide-no-data="!search" :search-input.sync="search" :rules="fieldRules"
                      @focus="checkAppNameUnique()">
                      <template v-slot:no-data>
                        <v-list-item>
                          <span class="subheading">เพิ่ม</span>
                          <v-chip label small :color="`${colors[nonce - 1]} lighten-3`">
                            {{ search }}
                          </v-chip>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{
                        attrs,
                        item,
                        parent,
                        selected,
                        index
                      }">
                        <v-chip label small v-if="item === Object(item)" v-bind="attrs" :color="`${item.color} lighten-3`"
                          :input-value="selected">
                          <span class="pr-2">{{ item.text }}</span>
                          <v-icon small @click="parent.selectItem(item), remove(index)">
                            mdi-close
                          </v-icon>
                        </v-chip>
                      </template>
                      <template v-slot:label>
                        <span>IP Address</span>
                        <span class="input-label"> *</span>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field outlined dense v-model="logoImg" :rules="field255Rules" counter="255"
                      @focus="checkAppNameUnique()">
                      <template v-slot:label>
                        <span>URL Logo</span>
                        <span class="input-label"> *</span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <img v-if="logoImg" :src="logoImg" alt="Application Logo" width="150" @error="onImgLoadError"
                      @load="onImgLoadSuccess" />
                  </v-col>
                  <v-col cols="12" md="6" xs="12">
                    <v-text-field outlined dense v-model="policyUrl" :rules="field255Rules" counter="255"
                      prefix="https://" placeholder="example.com" hint="https://example.com/"
                      @focus="checkAppNameUnique()">
                      <template v-slot:label>
                        <span>Policy</span>
                        <span class="input-label"> *</span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" xs="12">
                    <v-text-field outlined dense v-model="termsOfUseUrl" :rules="field255Rules" counter="255"
                      prefix="https://" placeholder="example.com" hint="https://example.com/"
                      @focus="checkAppNameUnique()">
                      <template v-slot:label>
                        <span>Terms of Use</span>
                        <span class="input-label"> *</span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" xs="12" v-if="false">
                    <v-text-field outlined dense v-model="webHook" label="Webhook" :rules="field128NotRequireRules"
                      counter="128" prefix="https://" placeholder="webhook.com" hint="https://webhook.com/"
                      @focus="checkAppNameUnique()" />
                  </v-col>
                  <div v-if="false">
                    <template v-for="(n, idx) in listHeaders">
                      <v-col :key="'key' + n" cols="12" md="5" xs="12">
                        <v-text-field outlined dense v-model="headersKeyCollections[idx]" prefix="Header Key :"
                          @focus="checkAppNameUnique()" />
                      </v-col>
                      <v-col :key="'val' + n" cols="12" md="5" xs="12">
                        <v-text-field outlined dense v-model="headersValCollections[idx]" prefix="Header Value :"
                          @focus="checkAppNameUnique()" />
                      </v-col>
                    </template>
                    <v-col cols="2" md="2">
                      <v-layout>
                        <v-flex class="mr-1">
                          <v-btn color="success" class="btn-webhook" @click="headerListValue">
                            +
                          </v-btn>
                        </v-flex>
                        <v-flex>
                          <v-btn color="error" class="btn-webhook" @click="rmvHeader(listHeaders - 1)"
                            v-if="listHeaders > 1">
                            -
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-col>
                  </div>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-2"></v-divider>

            <!-- Section 3: Needed Info -->
            <v-row d-flex justify="start" align="start" class="mt-1" v-if="formRole == '1' || formRole == '3'">
              <v-col cols="6" md="3" class="mt-5">
                <p>ข้อมูลที่ต้องการใช้ :</p>
              </v-col>
              <v-col cols="12" md="9">
                <v-row>
                  <template v-for="(list, idx) in lists">
                    <v-col :key="idx" cols="6" md="4">
                      <v-checkbox v-model="neededInfoScopes" :label="list.text" :value="list.value"></v-checkbox>
                    </v-col>
                  </template>
                </v-row>
              </v-col>
            </v-row>

            <!-- Section 4-1: Specification Document -->
            <v-row d-flex justify="start" align="start" v-if="formRole == '2' || formRole == '3'">
              <v-col cols="6" md="3" class="mt-5">
                <p>เอกสารการใช้งาน :</p>
              </v-col>
              <v-col cols="12" md="9" class="mt-3">
                <v-row>
                  <v-col cols="12" md="6" xs="12">
                    <v-text-field outlined dense v-model="apiSpecURL" label="Document URL (API Specification)"
                      @focus="checkAppNameUnique()"></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!-- Section 4-2: AS Needed -->
            <div v-if="false">
              <v-row d-flex justify="start" align="start" v-if="formRole == '1' || formRole == '3'">
                <v-col cols="6" md="3" class="mt-5">
                  <p>ข้อมูล AS ที่ต้องการใช้งาน :</p>
                </v-col>
                <v-col cols="12" md="9" class="mt-3">
                  <v-row>
                    <v-col cols="12">
                      <v-data-table v-model="asSelected" :show-select="asList.length > 0"
                        :hide-default-footer="asList.length < 1" :headers="headerAS" :items="asList" item-key="name"
                        class="elevation-1 rounded-t-xl rounded-b-lg" :headerProps="{ sortByText: 'เรียงตาม' }"
                        :footer-props="{
                          'items-per-page-text': 'แสดง',
                          'items-per-page-options': [10, 20, 30, -1],
                          'items-per-page-all-text': 'ทั้งหมด'
                        }">
                        <template v-slot:no-data>
                          <v-layout>
                            <v-flex xs12 class="secondary--text">ไม่มีข้อมูล</v-flex>
                          </v-layout>
                        </template>
                        <template #footer.page-text="props">
                          {{ props.pageStart }} -
                          {{ props.pageStop }} จากทั้งหมด
                          {{ props.itemsLength }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-8">
          <v-col cols="6" md="3">
            <v-btn class="width-100" x-large color="primary" :disabled="!isValidForm" @click="submit()">
              บันทึก
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import ip from 'ip'
// import Nprogress from 'nprogress'
import Swal from 'sweetalert2'
import { get } from 'vuex-pathify'
import { Applications, General } from '../../core/api'
import { errorHandler } from '../../core/js/handleError'

export default {
  name: 'RegisterApplication',
  data() {
    return {
      fieldRules: [v => !!v || 'กรุณากรอกข้อมูล'],
      field100Rules: [
        v => !!v || 'กรุณากรอกข้อมูล',
        v => (v && v.length <= 100) || 'ข้อมูลต้องไม่เกิน 100 ตัวอักษร'
      ],
      field255Rules: [
        v => !!v || 'กรุณากรอกข้อมูล',
        v => (v && v.length <= 255) || 'ข้อมูลต้องไม่เกิน 255 ตัวอักษร'
      ],
      field50Rules: [
        v => !!v || 'กรุณากรอกข้อมูล',
        v => (v && v.length <= 50) || 'ข้อมูลต้องไม่เกิน 50 ตัวอักษร'
      ],
      field128NotRequireRules: [
        v => v.length <= 128 || 'ข้อมูลต้องไม่เกิน 128 ตัวอักษร'
      ],
      field5_10Rules: [
        v => !!v || 'กรุณากรอกข้อมูล',
        v => (v && v.length >= 5) || 'กรุณากรอกข้อมูลอย่างน้อย 5 ตัวอักษร',
        v => (v && v.length <= 10) || 'ข้อมูลต้องไม่เกิน 10 ตัวอักษร'
      ],
      field300Rules: [
        v => !!v || 'กรุณากรอกข้อมูล',
        v => (v && v.length <= 300) || 'ข้อมูลต้องไม่เกิน 300 ตัวอักษร'
      ],

      lists: [
        { value: 'pid', text: 'เลขประจำตัวประชาชน' },
        { value: 'birthdate', text: 'วันเดือนปีเกิด' },
        { value: 'address', text: 'ที่อยู่ตามหน้าบัตร' },
        { value: 'title', text: 'คำนำหน้านามภาษาไทย' },
        { value: 'given_name', text: 'ชื่อภาษาไทย' },
        { value: 'middle_name', text: 'ชื่อกลางภาษาไทย' },
        { value: 'family_name', text: 'ชื่อสกุลภาษาไทย' },
        { value: 'title_en', text: 'คำนำหน้านามภาษาอังกฤษ' },
        { value: 'given_name_en', text: 'ชื่อภาษาอังกฤษ' },
        { value: 'middle_name_en', text: 'ชื่อกลางภาษาอังกฤษ' },
        { value: 'family_name_en', text: 'ชื่อสกุลภาษาอังกฤษ' },
        { value: 'name', text: 'ชื่อเต็มภาษาไทย' },
        { value: 'name_en', text: 'ชื่อเต็มภาษาอังกฤษ' },
        { value: 'gender', text: 'เพศ' },
        { value: 'date_of_issuance', text: 'วันที่ออกบัตร' },
        { value: 'date_of_expiry', text: 'วันที่หมดอายุ' },
        { value: 'smartcard_code', text: 'เลขใต้รูป' },
        { value: 'ial', text: 'ระดับความน่าเชื่อถือ (IAL Level)' }
      ],

      colors: ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange'],
      index: -1,
      items: [{ header: 'พิมพ์ IP Address แล้วกด Enter เพื่อเพิ่ม' }],
      nonce: 1,
      model: [],
      search: null,

      unfocusAppEnName: false,
      unfocusAppAbbreviate: false,
      appEnNameIsUnique: false,
      appAbbreviateIsUnique: false,

      formRole: '1',
      isValidForm: false,

      appName: null,
      appEnName: null,
      appAbbreviate: null,
      purpose: null,

      callbackUrl: null,
      ipAddressChips: [],
      logoImg: null,
      isValidLogoImg: false,
      policyUrl: null,
      termsOfUseUrl: null,
      webHook: '',
      listHeaders: 1,
      headersKeyCollections: [],
      headersValCollections: [],

      neededInfoScopes: [],
      apiSpecURL: null,

      asList: [],
      asSelected: [],
      headerAS: [
        {
          text: 'ชื่อแอปพลิเคชัน AS',
          align: 'start',
          sortable: false,
          value: 'name',
          class: 'rounded-tl-lg'
        },
        {
          text: 'เอกสารการใช้งาน',
          align: 'start',
          sortable: true,
          value: 'file',
          class: 'rounded-tr-lg'
        }
      ],

      dialog: false,
      keyCallBack: null,
      keyAPIKey: null,
      keyClientID: null,
      keyClientSecret: null
    }
  },
  mounted() {
    if (!this.userInfo) {
      this.$router.push('/')
    }
  },
  computed: {
    userInfo: get('userStore/userInfo'),
    userOrganization: get('userStore/userOrganization')
  },
  watch: {
    appEnName(val, prev) {
      this.appEnName = val.toLowerCase()

      if (val != prev) {
        this.appEnNameIsUnique = false
      }
    },
    appAbbreviate(val, prev) {
      if (val != prev) {
        this.appAbbreviateIsUnique = false
      }
    },
    model(val, prev) {
      if (val.length === prev.length) {
        return false
      }

      this.model = val.map(v => {
        if (typeof v === 'string') {
          v = {
            text: v
          }
          this.ipAddressChips.push(v.text)
          this.nonce++
        }
        return v
      })
    }
  },
  methods: {
    errorHandler: errorHandler,
    onImgLoadError() {
      this.isValidLogoImg = false
    },
    onImgLoadSuccess() {
      this.isValidLogoImg = true
    },
    rmvHeader(idx) {
      this.listHeaders--

      if (this.headersKeyCollections.length > 0) {
        this.headersKeyCollections.splice(idx, 1)
      }
      if (this.headersValCollections.length > 0) {
        this.headersValCollections.splice(idx, 1)
      }
    },
    headerListValue() {
      this.listHeaders++
    },
    checkIP(ip) {
      let isIP = ip
        .split('.')
        .map(e => /^(25[0-5]|2[0-4]\d|[01]?\d\d?)$/.test(e))
      return isIP.every(e => e === true)
    },
    checkURLImg(url) {
      return url.match(/\.(jpeg|jpg|gif|png|JPEG|JPG|GIF|PNG)$/) != null
    },
    closeDialogKey() {
      this.dialog = false
      this.keyCallBack = null
      this.keyAPIKey = null
      this.keyClientID = null
      this.keyClientSecret = null
      this.$router.push('/home')
    },
    isUrlValid(userInput) {
      let pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
        'i'
      ) // fragment locator
      return !!pattern.test(userInput)
    },
    checkAppNameUnique() {
      if (this.appEnName && !this.appEnNameIsUnique) {
        this.unfocusAppEnName = true
        setTimeout(() => {
          this.appEnNameIsUnique = true
          this.unfocusAppEnName = false
        }, 3000)
      }

      if (
        this.appAbbreviate &&
        this.appAbbreviate.length >= 5 &&
        !this.appAbbreviateIsUnique
      ) {
        this.unfocusAppAbbreviate = true
        setTimeout(() => {
          this.appAbbreviateIsUnique = true
          this.unfocusAppAbbreviate = false
        }, 2000)
      }
    },
    remove(index) {
      this.ipAddressChips = this.ipAddressChips.filter((r, idx) => {
        if (idx != index) {
          return r
        } else {
          return 0
        }
      })
    },
    async submit() {
      if (!this.$refs.entryForm.validate()) {
        Swal.fire({
          title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
          icon: 'warning'
        })
        return false
      }

      if (!this.appEnNameIsUnique) {
        Swal.fire({
          title: 'มีชื่อแอปพลิเคชันภาษาอังกฤษนี้ในระบบแล้ว',
          text: 'กรุณากรอกตั้งชื่อแอปพลิเคชันภาษาอังกฤษใหม่',
          icon: 'error'
        })
        return false
      }

      // if (!this.appAbbreviateIsUnique) {
      //   Swal.fire({
      //     title: 'มีชื่อย่อแอปพลิเคชันนี้ในระบบแล้ว',
      //     text: 'กรุณากรอกตั้งชื่อย่อแอปพลิเคชัน',
      //     icon: 'error'
      //   })
      //   return false
      // }

      if (this.ipAddressChips.length == 0) {
        Swal.fire({
          title: 'กรุณากรอกหมายเลข IP Address',
          icon: 'error'
        })
        return false
      }

      if (
        (this.formRole == '1' || this.formRole == '3') &&
        this.neededInfoScopes.length == 0
      ) {
        Swal.fire({
          title: 'กรุณากรอกข้อมูลที่ต้องการใช้',
          icon: 'error'
        })
        return false
      }

      if (this.formRole == '2' && !this.isUrlValid(this.apiSpecURL)) {
        Swal.fire({
          title: 'กรุณากรอก Document URL (API Specification) ให้ถูกต้อง',
          text: 'ตัวอย่าง https://example.com/avatar.pdf',
          icon: 'warning'
        })
        return false
      }

      if (!this.checkURLImg(this.logoImg)) {
        Swal.fire({
          title: 'กรุณากรอก URL Image ให้ถูกต้อง',
          text:
            'รองรับไฟล์รูปภาพ jpeg, jpg, gif, png ตัวอย่าง https://example.com/avatar.jpg',
          icon: 'warning'
        })
        return false
      }

      let headerTrueCollections = []
      this.headersKeyCollections.map((_, index) => {
        headerTrueCollections.push({
          headerKey: this.headersKeyCollections[index],
          headerValue: this.headersValCollections[index]
        })
      })

      if (this.termsOfUseUrl.substring(0, 8) != 'https://') {
        this.termsOfUseUrl = 'https://' + this.termsOfUseUrl
      }
      if (this.policyUrl.substring(0, 8) != 'https://') {
        this.policyUrl = 'https://' + this.policyUrl
      }
      if (this.webHook && this.webHook.substring(0, 8) != 'https://') {
        this.webHook = 'https://' + this.webHook
      }

      let validIP = []
      this.ipAddressChips.map(item => {
        if (!this.checkIP(item)) {
          Swal.fire({
            title: 'กรุณากรอกหมายเลข IP ให้ถูกต้อง',
            icon: 'error'
          })
          return false
        } else {
          validIP.push(item)
        }
      })

      let data = {
        orgID: this.userOrganization.organizeID,
        purpose: this.purpose,
        appAbbreviate: ' ',
        appName: this.appName,
        appEnName: this.appEnName,
        logoImg: this.logoImg,
        pid: this.userInfo.pid,
        role: parseInt(this.formRole),
        termsOfUseUrl: this.termsOfUseUrl,
        policyUrl: this.policyUrl,
        webHookUrl: this.webHook,
        webhookHeaders: headerTrueCollections,
        ipAddress: validIP,
        callbackUrl: this.callbackUrl,
        scopes: this.neededInfoScopes,
        ASApiSpecUrl: this.apiSpecURL,
        asUsages: this.asSelected
      }

      let body = {}
      if (this.formRole == '1') {
        body = data
        delete body.ASApiSpecUrl
      } else if (this.formRole == '2') {
        body = data
        delete body.callbackUrl
        delete body.scopes
        delete body.asUsages
      } else if (this.formRole == '3') {
        body = data
      }

      try {
        let type = localStorage.getItem('type')
        type = type === 'main' ? 'idp' : 'sandbox'
        let res = await Applications.postApplications(body, type)
        if (res.status == 200) {
          let dataKey = res.data.data
          Swal.fire({
            title: 'บันทึกสำเร็จ',
            icon: 'success'
          }).then(async () => {
            this.dialog = true
            this.keyCallBack = this.callbackUrl
            this.keyAPIKey = dataKey.apiKey
            this.keyClientID = dataKey.clientID
            this.keyClientSecret = dataKey.clientSecret

            let data = {
              requestPid: Number(this.userInfo.pid),
              orgID: this.userOrganization.organizeID,
              ipAddress: ip.address(),
              detail: 'สร้าง application ' + this.appName
            }
            try {
              await General.postActivityLogs(data)
            } catch (err) {
              console.error(err)
            }
          })
        } else {
          Swal.fire({
            title: 'บันทึกไม่สำเร็จ',
            icon: 'error',
            text: res.data.data
          })
        }
      } catch (e) {
        Swal.fire({
          title: 'บันทึกไม่สำเร็จ',
          icon: 'error',
          text: this.errorHandler(e.response)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input-label {
  color: #c30012;
}

.v-btn:not(.v-btn--round).v-size--default.btn-webhook {
  height: 49px;
  min-width: 48px !important;
}

.radio-company-type {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 2.5rem;

  p {
    font-size: 1rem;
    height: 1.5rem;
    padding: 0;
    margin-bottom: 5px;
    margin-right: 2px;
  }
}

.width-100 {
  width: 100%;
}

.container-page {
  position: relative;
  min-height: 100vh;
  width: 100vw;
  z-index: 00;
}

.bg-app {
  background: url(~@/assets/add-app-recolor.png) right;
  background-size: auto;
  background-repeat: no-repeat;
  background-origin: content-box;
}

@media only screen and (max-width: 600px) {
  .bg-app {
    background: none;
  }
}

.v-data-table::v-deep th {
  font-size: 1rem !important;
  font-weight: 400 !important;
  color: #f6f6fa !important;
  background: #3a3d80;
}

.v-data-table::v-deep tbody tr:nth-of-type(even) {
  background-color: #ecf0fe;
}

.v-data-table::v-deep th i {
  color: rgba(255, 255, 255, 0.8) !important;
}

.v-data-table::v-deep th i:hover {
  color: rgba(255, 255, 255, 1) !important;
}

.v-data-table::v-deep .rounded-tl-lg {
  border-top-left-radius: 4px !important;
}

.v-data-table::v-deep .rounded-tr-lg {
  border-top-right-radius: 4px !important;
}

.rounded-b-lg {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
</style>
