<template>
  <div>
    <v-card :color="color" class="card-stat">
      <div>
        <span class="number-text">{{ formatNumber(value) || 0 }}</span>
      </div>
      <div>
        <span class="topic-text">{{ topic }}</span>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: [String, Number]
    },
    topic: {
      type: String,
      default: 'Topic'
    },
    value: {
      type: [String, Number],
      default: 0
    }
  },
  methods: {
    formatNumber(number) {
      return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
  }
}
</script>

<style lang="scss" scoped>
.card-stat {
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  flex-direction: column;
}

.number-text {
  font-size: 20pt;
  font-weight: 400;
}

.topic-text {
  font-size: 14pt;
  font-weight: 300;
}
</style>
