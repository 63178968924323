import { api } from './index'

const prefix = '/idp/api/v1/officers'

export default {
  getOfficers() {
    return api.get(`${prefix}/`)
  },
  putOfficer(id, data) {
    return api.put(`${prefix}/${id}`, data)
  },
  postRequestOfficer(data) {
    return api.post(`${prefix}/`, data)
  },
  postEnroll(data) {
    return api.post(`/idp/api/v1/enrollOfficers/`, data)
  }
}
