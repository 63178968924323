<template>
  <v-app>
    <base-app-bar />

    <v-main class="main-style">
      <router-view></router-view>
    </v-main>

    <base-footer class="footer-style" />
  </v-app>
</template>

<script>
import BaseAppBar from '../components/BaseAppBar.vue'
import BaseFooter from '../components/BaseFooter.vue'

export default {
  name: 'HomePage',
  components: { BaseAppBar, BaseFooter },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.main-style {
  height: 90%;
  width: 100%;
}

.footer-style {
  bottom: 0px;
  z-index: 1;
}
</style>
