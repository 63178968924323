<template>
  <div class="logged-wrapper bg-select-app">
    <v-row>
      <v-col cols="12" class="mb-10" justify="center">
        <v-row>
          <v-col cols="12">
            <base-page-header title="เลือก Environment ที่จะทำงาน"></base-page-header>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-layout wrap class="justify-start">
              <v-card outlined height="400px" min-width="300px" class="menu-card text-center pa-2 mb-5 mx-5">
                <v-layout column fill-height class="justify-space-around">
                  <div>
                    <div>
                      <span class="menu-card-title">PRODUCTION</span>
                    </div>
                  </div>
                  <div>
                    <v-img src="../assets/prodicon.png" alt="logoOrg" aspect-ratio="3" contain></v-img>
                  </div>
                  <div class="px-5">
                    <v-btn dark block large depressed color="#3a3d80" @click="selectEnviType('main')">
                      <span>เลือก</span>
                    </v-btn>
                  </div>
                </v-layout>
              </v-card>
              <v-card outlined height="400px" min-width="300px" class="menu-card text-center pa-2 mb-5 mx-5"
                style="border: 5px solid #f89929;">
                <v-layout column fill-height class="justify-space-around">
                  <div>
                    <div>
                      <span style="color: #f89929;" class="menu-card-title">SANDBOX</span>
                    </div>
                  </div>
                  <div>
                    <v-img src="../assets/sandboxicon.png" alt="logoOrg" aspect-ratio="3" contain></v-img>
                  </div>
                  <div class="px-5">
                    <v-btn dark block large depressed style="background-color: rgb(239 148 40);"
                      @click="selectEnviType('sandbox')">
                      <span>เลือก</span>
                    </v-btn>
                  </div>
                </v-layout>
              </v-card>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { sync } from 'vuex-pathify'

export default {
  name: 'SelectEnvironment',
  data() {
    return {
      orgList: [],
    }
  },
  computed: {
    userOrganization: sync('userStore/userOrganization'),
    userInfo: sync('userStore/userInfo'),
    typeEnvi: sync('userStore/typeEnvi'),
  },
  async mounted() {
    localStorage.setItem('type', 'main')
    this.typeEnvi = 'main'
  },
  methods: {
    async selectEnviType(enviType) {
      localStorage.setItem('type', enviType)
      this.typeEnvi = enviType
      this.$router.push({ name: 'ApplicationManage' })
    }
  }
}
</script>

<style lang="scss" scoped>
.logged-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 1.5rem;
}

.bg-select-app {
  background: url(~@/assets/login-bg.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: right;
  background-size: 85%;
}

.menu-card {
  border: 5px solid #3a3d80;
}

.menu-card-title {
  color: #3a3d80;
  font-size: 14pt;
  font-weight: 800;
}

.menu-card-text {
  color: #3a3d80;
  font-size: 10pt;
  font-weight: 500;
}
</style>
