<template>
  <div>
    <v-card>
      <v-card-title class="px-3 py-2">
        <span class="primary--text font-weight-bold">ค้นหาเจ้าหน้าที่</span>
        <v-spacer></v-spacer>
        <v-text-field outlined dense hide-details v-model="search" prepend-inner-icon="mdi-magnify"
          class="search-box mt-0 pt-0"></v-text-field>
      </v-card-title>

      <v-data-table class="elevation-1" :loading="officersBody == []" :items-per-page="10" :headers="headers"
        :items="officersBody" :search="search" :headerProps="{ sortByText: 'เรียงตาม' }" :footer-props="{
          'items-per-page-text': 'แสดง',
          'items-per-page-options': [10, 20, 30, -1],
          'items-per-page-all-text': 'ทั้งหมด'
        }">
        <template v-slot:item.pid="{ item }">
          <span class="primary--text font-weight-bold">
            {{ formatPid(item.pid) }}
          </span>
        </template>
        <template v-slot:item.allow="{ item }">
          <span class="primary--text">
            {{ getOfficerAllowDesc(item.allow) }}
          </span>
        </template>
        <template v-slot:item.role="{ item }">
          <span class="primary--text">
            {{ role(item.role) }}
          </span>
        </template>
        <template v-slot:item.active="{ item }">
          <v-btn v-if="item.active === 1" icon :ripple="false" id="no-background-hover" @click="updateOfficer(item)">
            <v-icon color="green">mdi-check</v-icon>
          </v-btn>
          <v-btn v-else icon :ripple="false" id="no-background-hover" @click="updateOfficer(item)">
            <v-icon color="red">mdi-window-close</v-icon>
          </v-btn>
        </template>
        <template #footer.page-text="props">
          {{ props.pageStart }} - {{ props.pageStop }} จากทั้งหมด
          {{ props.itemsLength }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { formatPID } from '@/core/js/format'
import { Officers } from '@/core/api'
import { get } from 'vuex-pathify'
import Swal from 'sweetalert2'

export default {
  name: 'OfficerInfo',
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'เลขประจำตัวประชาชน',
          align: 'center',
          sortable: false,
          value: 'pid'
        },
        {
          text: 'สถานะ',
          align: 'center',
          sortable: true,
          value: 'allow'
        },
        {
          text: 'Level',
          align: 'center',
          sortable: true,
          value: 'role'
        },
        {
          text: 'Active',
          align: 'center',
          sortable: true,
          value: 'active'
        }
      ],
      officersBody: [],
      roleOfficer: 0
    }
  },
  computed: {
    userInfo: get('userStore/userInfo'),
    userOrganization: get('userStore/userOrganization')
  },
  async mounted() {
    setTimeout(async () => {
      await this.getOfficer()
      const result = this.userInfo.list.find(member => {
        return member.organizeID === this.userOrganization.organizeID
      })
      this.roleOfficer = result.role
    }, 500);
  },
  methods: {
    formatPid: formatPID,
    active(code) {
      if (code === 1) return "Senior IDP Admin"
      if (code === 2) return "IDP Admin"
    },
    role(code) {
      if (code === 1) return "Senior IDP Admin"
      if (code === 2) return "IDP Admin"
    },
    getOfficerAllowDesc(code) {
      if (code === 0) {
        return 'รออนุมัติ'
      } else if (code === 1) {
        return 'อนุมัติ'
      } else if (code === 2) {
        return 'ไม่อนุมัติ'
      }
    },
    async getOfficer() {
      const officer = await Officers.getOfficers(this.userOrganization.organizeID)
      officer.data.map(r => {
        if (
          parseInt(r.organizeID) == parseInt(this.userOrganization.organizeID)
        ) {
          this.officersBody.push(r)
        }
      })
    },
    async updateOfficer(officer) {
      if (officer.active != 1 && officer.active != 3) {
        return Swal.fire({
          title: 'ไม่สามารถแก้ไขข้อมูลได้สถานะ Active ไม่ถูกต้อง',
          icon: 'warning'
        })
      }
      if (this.roleOfficer != 1) {
        return Swal.fire({
          title: 'ไม่สามารถแก้ไขข้อมูลได้ (เฉพาะ Senior IDP Admin เท่านั้น)',
          icon: 'warning'
        })
      }
      if (officer.pid == this.userInfo.pid) {
        return Swal.fire({
          title: 'ไม่สามารถแก้ไขข้อมูลของตัวเองได้',
          icon: 'warning'
        })
      }
      Swal.fire({
        title: 'ต้องการแก้ไขข้อมูลสถานะ Active หรือไม่',
        showCancelButton: true,
        icon: 'warning'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const active = officer.active == 1 ? 3 : 1
          let result = await Officers.putOfficer(officer.officerImauthID, {
            is_active: active
          })
          if (result.status == 200) {
            Swal.fire({
              title: 'บันทึกสำเร็จ',
              icon: 'warning'
            })
            result = await Officers.getOfficers(this.userOrganization.organizeID)
            this.officersBody = []
            result.data.map(r => {
              if (
                parseInt(r.organizeID) == parseInt(this.userOrganization.organizeID)
              ) {
                this.officersBody.push(r)
              }
            })
          } else {
            Swal.fire({
              title: 'บันทึกไม่สำเร็จ',
              icon: 'warning',
              text: result.statusText
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.search-box {
  width: 0px !important;
}

.v-data-table::v-deep th {
  font-size: 1rem !important;
  font-weight: 400 !important;
  color: #f6f6fa !important;
  background: #3a3d80;
}

.v-data-table::v-deep tbody tr:nth-of-type(even) {
  background-color: #ecf0fe;
}

.v-data-table::v-deep th i {
  color: rgba(255, 255, 255, 0.8) !important;
}

.v-data-table::v-deep th i:hover {
  color: rgba(255, 255, 255, 1) !important;
}

.v-data-table::v-deep .rounded-tl-lg {
  border-top-left-radius: 4px !important;
}

.v-data-table::v-deep .rounded-tr-lg {
  border-top-right-radius: 4px !important;
}

.rounded-b-lg {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
</style>
