<template>
  <div class="container-page">
    <div class="bg-img bg-officer"></div>
    <!-- Page title -->
    <base-page-header title="ลงทะเบียนเจ้าหน้าที่" subtitle="หน่วยงานภายนอกลงทะเบียนเจ้าหน้าที่"></base-page-header>

    <!-- Form -->
    <div class="form-wrapper mt-8">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col md="2" cols="12" style="display: flex;">
            <p class="label-form">ประเภทองค์กร :</p>
          </v-col>
          <v-col md="3" cols="6">
            <v-select outlined dense :items="items" item-text="title" item-value="value" v-model="select"
              label="หน่วยงาน" />
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="mt-4">
          <v-col md="2" cols="12" style="display: flex;">
            <p class="label-form">ข้อมูลหน่วยงาน :</p>
          </v-col>
          <v-col>
            <v-row>
              <v-col md="12" cols="12">
                <v-text-field outlined dense required label="ชื่อหน่วยงาน / สำนัก" v-model="org_name"
                  :rules="rules"></v-text-field>
              </v-col>
              <template v-if="select == `0`">
                <v-col md="6" cols="6">
                  <v-text-field outlined dense :rules="select == `0` ? rules : ''" label="ชื่อกระทรวง"
                    v-model="ministry"></v-text-field>
                </v-col>
                <v-col md="6" cols="6">
                  <v-text-field outlined dense :rules="select == `0` ? rules : ''" label="ชื่อกรม"
                    v-model="department"></v-text-field>
                </v-col>
              </template>
              <v-col md="6" cols="6">
                <v-text-field outlined dense required label="เลขประจำตัวประชาชนผู้มีอำนาจลงนาม" v-model="authorized_pid"
                  v-mask="`#-####-#####-##-#`" :rules="pidRules"></v-text-field>
              </v-col>
              <v-col md="6" cols="6">
                <v-text-field outlined dense required label="ชื่อเต็มผู้มีอำนาจลงนาม" v-model="authorized_fullname"
                  :rules="rules"></v-text-field>
              </v-col>
              <v-col md="6" cols="6">
                <v-text-field outlined dense required label="ตำแหน่งผู้มีอำนาจลงนาม" v-model="position"
                  :rules="rules"></v-text-field>
              </v-col>
              <v-col md="6" cols="6">
                <v-text-field outlined dense required label="ชื่อสถานที่ทำงาน" v-model="address_name"
                  :rules="rules"></v-text-field>
              </v-col>
              <v-col md="12" cols="12">
                <v-textarea outlined dense required label="ที่อยู่ เลขที่ ตรอก ซอย ถนน หมู่ที่" v-model="address_desc"
                  :rules="rules"></v-textarea>
              </v-col>
              <v-col md="6" cols="6">
                <v-text-field outlined dense required label="เบอร์โทร" v-model="telephone" :rules="rules"
                  v-mask="`###-###-####`"></v-text-field>
              </v-col>
              <v-col md="6" cols="6">
                <v-text-field outlined dense required label="อีเมล" type="email" v-model="email"
                  :rules="emailRules"></v-text-field>
              </v-col>
              <v-col md="4" cols="6">
                <v-select outlined dense required :items="listCC" item-text="cc_desc" v-model="ccDesc" return-object
                  @change="getAAList(ccDesc)" label="จังหวัด" :rules="rules"></v-select>
              </v-col>
              <v-col md="4" cols="6">
                <v-select outlined dense required :disabled="ccDesc == null" :items="listAA" item-text="aa_desc"
                  item-value="aa_desc" v-model="aaDesc" @change="setCCAA(aaDesc)" label="อำเภอ" :rules="rules"></v-select>
              </v-col>
              <v-col md="4" cols="6">
                <v-text-field outlined dense required :disabled="aaDesc == null" label="ตำบล" v-model="ttDesc"
                  :rules="rules"></v-text-field>
              </v-col>
              <v-col md="6" cols="6">
                <v-text-field outlined dense required label="เลขที่หนังสือขอใช้ระบบ" v-model="document_no"
                  :rules="rules"></v-text-field>
              </v-col>
              <v-col md="6" cols="6">
                <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y
                  min-width="auto" content-class="menu-bottom">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="dateFormatted" append-icon="mdi-calendar" v-bind="attrs" v-on="on"
                      placeholder="ลงวันที่" outlined readonly dense></v-text-field>
                  </template>
                  <v-date-picker v-model="date" no-title @input="menu1 = false" locale="th"
                    :max="currentDate"></v-date-picker>
                </v-menu>
                <!-- <v-menu
                  v-model="menu1"
                  transition="scale-transition"
                  min-width="auto"
                  :close-on-content-click="false"
                  content-class="menu-bottom"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-row
                      align="end"
                      v-show="type === 'date'"
                      style="margin: 0px;"
                    > -->
                <!-- <div class="font-weight-medium subtitle-1">
                        ช่วงวันที่
                      </div> -->
                <!-- <v-text-field
                        v-model="computedDateFormatted1"
                        v-bind="attrs"
                        v-on="on"
                        placeholder="ลงวันที่"
                        outlined
                        readonly
                        dense
                        append-icon="mdi-calendar"
                        @click:append="menu1 = !menu1"
                        hide-details
                        :style="{ display: 'contents' }"
                        :disabled="loading"
                      ></v-text-field>
                    </v-row>
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="yyyymmdd"
                    locale="th"
                    type="date"
                    reactive
                    no-title
                    scrollable
                    range
                    clearable
                  ></v-date-picker>
                </v-menu> -->
                <!-- <v-text-field
                  outlined
                  dense
                  label="วันที่ลงหนังสือ"
                  v-model="bookDate"
                  :rules="rules"
                ></v-text-field> -->
                <!-- <v-text-field
                  outlined
                  dense
                  label="File หนังสือขอใช้ระบบ"
                  v-model="document_file"
                  :rules="rules"
                ></v-text-field> -->
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col md="2" cols="12" style="display: flex;">
            <p class="label-form">เจ้าหน้าที่ผู้ประสานงาน :</p>
          </v-col>
          <v-col>
            <v-row>
              <v-col md="5" cols="6">
                <v-text-field outlined dense placeholder="#-####-#####-##-#" v-mask="`#-####-#####-##-#`" v-model="co_pid"
                  label="เลขประจำตัวประชาชน" :rules="pidRules" />
              </v-col>
              <v-col md="7" cols="6">
                <v-text-field outlined dense v-model="co_name" label="ชื่อ-สกุล" :rules="rules" />
              </v-col>
              <v-col md="4" cols="6">
                <v-text-field outlined dense required label="เบอร์โทร" v-model="co_tel" :rules="rules"
                  v-mask="`###-###-####`"></v-text-field>
              </v-col>
              <v-col md="5" cols="6">
                <v-text-field outlined dense required label="อีเมล" type="email" v-model="co_email"
                  :rules="emailRules"></v-text-field>
              </v-col>
              <v-col md="3" cols="6" style="margin-top: -10px;">
                <v-checkbox v-model="chkUserRole" :label="`Senior IDP Admin (ผู้ดูแลระบบของหน่วยงาน)`"></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="mt-4">
          <v-col>
            <template v-for="(task, idx) in tasks">
              <v-row :key="idx">
                <v-col md="2" cols="12" style="display: flex;">
                  <p v-if="idx === 0" class="label-form">
                    {{ 'Senior IDP Admin :' }}
                  </p>
                  <p v-else class="label-form">
                    {{ 'IDP Administrator :' }}
                  </p>
                </v-col>
                <v-col>
                  <v-row>
                    <v-col md="4" cols="5">
                      <v-text-field outlined dense placeholder="#-####-#####-##-#" v-mask="`#-####-#####-##-#`"
                        v-model="pidCollections[idx]" label="เลขประจำตัวประชาชน" :rules="pidRules" />
                    </v-col>
                    <v-col md="5" cols="5">
                      <v-text-field outlined dense v-model="nameCollections[idx]" label="ชื่อ-สกุล" :rules="rules" />
                    </v-col>
                    <!-- <v-col md="2" cols="2">
                      <v-layout>
                        <v-flex class="mr-1" v-if="idx == tasks - 1 && tasks != 3">
                          <v-btn dense class="btn-personal-data" color="success" @click="addTasks()">
                            +
                          </v-btn>
                        </v-flex>
                        <v-flex v-if="idx == tasks - 1 && idx != 0">
                          <v-btn dense class="btn-personal-data" color="error" @click="removeTasks(idx)">
                            -
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-col> -->
                  </v-row>
                </v-col>
              </v-row>
            </template>
          </v-col>
        </v-row>
      </v-form>

      <v-row>
        <v-col cols="12" md="3">
          <v-btn x-large color="primaryDarken1" class="white--text" @click="registerOfficer()">
            ยื่นคำร้อง
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import ip from 'ip'
import Swal from 'sweetalert2'
import { Officers, Catm, General } from '../core/api'
import { generatePdf } from '@/core/js/createPdf'
import { get } from 'vuex-pathify'
import { formatPID } from '@/core/js/format'
import { api } from '@/core/api'
import axios from 'axios'
import { isValidPID } from '@/core/js/validate'

export default {
  name: 'RegisterOfficer',
  data() {
    return {
      dateQuery: null,
      date: null,
      dateFormatted: null,
      currentDate: new Date().toISOString().substr(0, 10),
      loading: false,
      chkUserRole: false,
      valid: false,
      rules: [v => !!v || 'กรุณากรอกข้อมูล'],
      emailRules: [v => /.+@.+/.test(v) || 'รูปแบบ email ไม่ถูกต้อง'],
      pidRules: [v => !!v || 'กรุณากรอกข้อมูล'],
      pid: null,
      organize_type: null,
      org_name: null,
      ministry: null,
      department: null,
      authorized_pid: null,
      authorized_fullname: null,
      address_name: null,
      address_desc: null,
      document_no: null,
      menu1: false,
      yyyymmdd: [],
      type: 'date',
      pickerType: 'date',
      pickerFormat: 'DD MMMM YYYY',
      user_role: 0, // 2 = IDP Admin, 1 = Senior Admin, 0 = ผู้ประสานงาน
      co_pid: null,
      co_name: null,
      co_email: null,
      co_tel: null,
      senior: {
        pid: null,
        name: null,
        phone: null,
        email: null,
        isSenior: false
      },
      position: null,
      telephone: null,
      task: null,
      tasks: 1,
      pidCollections: [],
      nameCollections: [],
      select: 0,
      email: null,
      listCC: [],
      listAA: [],
      ccDesc: null,
      aaDesc: null,
      ttDesc: null,
      items: [
        {
          title: 'ภาครัฐ',
          value: 0
        },
        {
          title: 'ภาคเอกชน',
          value: 1
        }
      ],
      countClick: 0
    }
  },
  computed: {
    userInfo: get('userStore/userInfo'),
    userOrganization: get('userStore/userOrganization'),
  },
  async created() {
    await this.getCCList()
    setTimeout(() => {
      this.co_pid = this.userInfo.pid
      this.co_name = `${this.userInfo.firstName} ${this.userInfo.middleName
        ? `${this.userInfo.middleName} ${this.userInfo.lastName}`
        : `${this.userInfo.lastName}`
        }`
    }, 500)
  },
  watch: {
    chkUserRole: function (val) {
      this.pidCollections[0] = null
      this.nameCollections[0] = null
      if (val === true) {
        this.pidCollections[0] = this.co_pid
        this.nameCollections[0] = this.co_name
      }
    },
    date(val) {
      this.dateFormatted = this.formatDate(val)
      this.dateQuery = this.formatDate(val)
    }
  },
  async mounted() {
    if (this.$router.history.current.hash) {
      let urlParams = this.$router.history.current.hash.split('/')[2]
      urlParams = urlParams.replace('?', '')
      let jsonObj = JSON.parse(
        '{"' +
        decodeURI(urlParams)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
      )
      Swal.fire({
        title: 'เข้าสู่ระบบล้มเหลว',
        icon: 'error',
        text: jsonObj.errorMessage
      }).then(() => {
        this.$router.replace('/login')
      })
    }
  },
  methods: {
    formatPid: formatPID,
    isValidPID,
    generatePdf,
    addTasks() {
      this.tasks++
    },
    removeTasks(index) {
      this.tasks--
      this.pidCollections.splice(index, 1)
      this.nameCollections.splice(index, 1)
    },
    genPdfData(data) {
      this.generatePdf(data)
    },
    async getCCList() {
      let ccList = await Catm.getCC()
      this.listCC = ccList.data.data
    },
    async getAAList(cc) {
      let aaList = await Catm.getAA(cc.cc_code)
      this.listAA = aaList.data.data.map(r => ({
        ...r,
        cc_desc: cc.cc_desc
      }))
    },
    setCCAA(aa) {
      this.listAA.map(r => {
        if (r.aa_desc == aa) {
          this.ccDesc = r.cc_desc
          this.aaDesc = r.aa_desc
        }
      })
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${Number(day)}/${Number(month)}/${Number(year) + 543}`
    },
    formatDateQuery(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${Number(year) + 543}${month}${day}`
    },
    async signOut() {
      sessionStorage.removeItem('app')
      localStorage.removeItem('type')

      let data = {
        requestPid: Number(this.userInfo.pid),
        orgID: this.userOrganization === null ? 0 : this.userOrganization.organizeID,
        ipAddress: ip.address(),
        detail: 'ออกจากระบบ'
      }
      try {
        await General.postActivityLogs(data)
      } catch (err) {
        console.error(err)
      }

      await axios
        .get(`/idp/api/login/v1/oauth2/logout/`)
        .then(() => {
          api.defaults.headers.common['Authorization'] = ''
          window.location = window.location.origin + "/idp/"
        })
        .catch(function (error) {
          console.log(error)
          window.location = window.location.origin + "/idp/"
        })
        .then(() => {
          window.location = window.location.origin + "/idp/"
          window.location.reload()
        })
    },
    async registerOfficer() {
      this.countClick++
      if (this.countClick != 1) return
      setTimeout(() => {
        this.countClick = 0
      }, 1000)

      if (this.$refs.form.validate()) {
        let pidTrueCollections = []
        for (let i of this.pidCollections) {
          if (i.length < 17) {
            Swal.fire({
              title: 'บันทึกไม่สำเร็จ',
              icon: 'error',
              text: i + ' ไม่ถูกต้อง'
            })
          } else {
            pidTrueCollections.push(i)
          }
        }
        if (this.isValidPID(obj.co_pid.split('-').join(''))) {
          Swal.fire({
            title: 'แจ้งเตือน',
            icon: 'error',
            text: 'รูปแบบเลขบัตรประชาชนเจ้าหน้าที่ผู้ประสานงานไม่ถูกต้อง'
          })
          return
        }
        for (let i = 0; i < this.pidCollections.length; i++) {
          if (this.isValidPID(pidTrueCollections[i].split('-').join(''))) {
            if (i == 0) {
              Swal.fire({
                title: 'แจ้งเตือน',
                icon: 'error',
                text: 'รูปแบบเลขบัตรประชาชน Senior IDP Admin ไม่ถูกต้อง'
              })
            } else {
              Swal.fire({
                title: 'แจ้งเตือน',
                icon: 'error',
                text: 'รูปแบบเลขบัตรประชาชน IDP Administrator ไม่ถูกต้อง'
              })
            }
            return
          }
        }
        let obj = this

        try {
          if (this.pidCollections.length === 0 && !this.chkUserRole)
            this.user_role = 0
          else if (this.chkUserRole) this.user_role = 1
          else this.user_role = 2

          let addPid = Number(obj.userInfo.pid) || 0
          // const payload = {
          //   orgType: obj.select == 0 ? 'ภาครัฐ' : 'เอกชน',
          //   orgName: obj.org_name,
          //   ministry: obj.select == 0 ? obj.ministry : ` `,
          //   department: obj.select == 0 ? obj.department : ` `,
          //   authorizedPid: Number(obj.authorized_pid.split('-').join('')),
          //   authorizedFullname: obj.authorized_fullname || ` `,
          //   position: obj.position,
          //   addressName: obj.address_name,
          //   addressDesc: obj.address_desc,
          //   documentNo:
          //     obj.document_no.toString() + ' ลว. ' + obj.dateQuery.toString(),
          //   documentFile: ' ',
          //   // bookDate: obj.yyyymmdd,
          //   // senior
          //   coPid: Number(obj.co_pid.split('-').join('')),
          //   coName: obj.co_name,
          //   coTel: obj.co_tel,
          //   coEmail: obj.co_email,

          //   mark: ` `,
          //   tel: obj.telephone.split('-').join(''),
          //   email: obj.email,
          //   aaDesc: obj.aaDesc,
          //   ccDesc: obj.ccDesc,
          //   ttDesc: obj.ttDesc,
          //   requestPid: addPid
          // }

          let arr = []
          for (let i = 0; i < this.pidCollections.length; i++) {
            const payloads = {
              orgType: obj.select == 0 ? 'ภาครัฐ' : 'เอกชน',
              orgName: obj.org_name.trim(),
              ministry: obj.select == 0 ? obj.ministry : ` `,
              department: obj.select == 0 ? obj.department : ` `,
              authorizedPid: Number(obj.authorized_pid.split('-').join('')),
              authorizedFullname: obj.authorized_fullname,
              position: obj.position,
              addressName: obj.address_name,
              addressDesc: obj.address_desc,
              documentNo:
                obj.document_no.toString() + ' ลว. ' + obj.dateQuery.toString(),
              documentFile: ' ',
              // bookDate: obj.yyyymmdd,
              // senior
              coPid: Number(obj.co_pid.split('-').join('')),
              coName: obj.co_name,
              coTel: obj.co_tel,
              coEmail: obj.co_email,
              userRole: i === 0 ? 1 : 2,
              mark: ` `,
              tel: obj.telephone.split('-').join(''),
              email: obj.email,
              pid: Number(pidTrueCollections[i].split('-').join('')),
              aaDesc: obj.aaDesc,
              ccDesc: obj.ccDesc,
              ttDesc: obj.ttDesc,
              fullname: obj.nameCollections[i],
              requestPid: addPid
            }
            const r = await Officers.postEnroll(payloads).catch(error => error)
            arr.push(r)
          }

          try {
            // let rr = await Promise.all(arr)
            // rr = rr.map((r, idx) => {
            //   const {
            //     data: {
            //       data: { enroll_id }
            //     }
            //   } = r
            //   return {
            //     enroll_id,
            //     fullname: obj.nameCollections[idx],
            //     pid: pidTrueCollections[idx]
            //   }
            // })
            // const { ...rest } = payload
            // let sentMakePDF = {
            //   persons: rr,
            //   ...rest
            // }
            Swal.fire({
              title: 'ส่งคำร้องสำเร็จ',
              icon: 'success'
            }).then(async () => {
              // obj.genPdfData(sentMakePDF)

              // obj.organize_type = 0
              // obj.org_name = null
              // obj.ministry = null
              // obj.department = null
              // obj.authorized_pid = null
              // obj.authorized_fullname = null
              // obj.position = null
              // obj.address_name = null
              // obj.address_desc = null
              // obj.document_no = null
              // obj.dateQuery = null
              // // obj.document_file = null
              // obj.yyyymmdd = []
              // // senior
              // obj.co_pid = null
              // obj.co_name = null
              // obj.co_tel = null
              // obj.co_email = null
              // obj.user_role = 0
              // //

              // obj.mark = null
              // obj.tel = null
              // obj.email = null
              // obj.ccDesc = null
              // obj.aaDesc = null
              // obj.ttDesc = null
              // obj.pidCollections = []
              // obj.nameCollections = []
              // obj.telephone = null

              let data = {
                requestPid: Number(this.userInfo.pid),
                orgID: this.userOrganization === null ? 0 : this.userOrganization.organizeID,
                ipAddress: ip.address(),
                detail: 'ลงทะเบียนหน่วยงาน'
              }

              try {
                await General.postActivityLogs(data)
              } catch (err) {
                console.error(err)
              }

              // this.$router.push({ name: 'Login' })
              await this.signOut()
            })
          } catch (e) {
            Swal.fire({
              title: 'บันทึกไม่สำเร็จ',
              icon: 'error',
              text: e.message
            })
          }
        } catch (e) {
          Swal.fire({
            title: 'บันทึกไม่สำเร็จ',
            icon: 'error',
            text: e.message
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bg-officer {
  background: url(~@/assets/app-info-recolor-mini-alt.png) right;
  background-size: 60%;
  background-repeat: no-repeat;
}

.v-btn:not(.v-btn--round).v-size--default.btn-personal-data {
  height: 49px;
  min-width: 50px !important;
}
</style>
