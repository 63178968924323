import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primaryDarken2: '#04073b',
        primaryDarken1: '#181b54',
        primary: '#3a3d80',
        primaryLighten1: '#979aca',
        primaryLighten2: '#e4e4f1',
        primaryLighten3: '#ecf0fe',
        primaryLighten4: '#f6f6fa',

        secondaryDarken1: '#2c2738',
        secondary: '#7a7880',
        secondaryLighten1: '#c5c5c5',
        secondaryLighten2: '#ebebeb',

        success: '#2e5f16',
        successLighten: '#e6f9ce',
        successLighten2: '#B5DC4D',
        error: '#c13a3a',
        errorLighten: '#f3d3d3',
        warning: '#ffcc49',

        approved: '#417b5a',
        pending: '#004db6',
        disapproved: '#ea3546',
        timeout: '#39456a',
        edit: '#ffa630',
        delete: '#c30012',

        info: '#0291f8'
      }
    }
  }
})
