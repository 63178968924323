import pdfmakeCustom from '@cdglib/js-pdfmake-custom/src/plugin/pdfmake-custom'
import printDoc from './print'

function createPersonTable(persons) {
  let table = {
    style: ['tableExample', { lineHeight: 1.25 }],
    table: { widths: ['15%', '30%', '30%', 'auto'] }
  }
  let body = [
    ['ลำดับที่', 'เลขประจำตัวประชาชน', 'ชื่อ-สกุล', 'เลขที่ใบสมัคร'].map(r => ({
      text: r,
      alignment: 'center'
    }))
  ]

  if (persons.length === 0) return []

  let idx = 1
  for (let p of persons) {
    const { pid, fullname, enroll_id } = p
    body.push([
      { text: idx, alignment: 'center' },
      { text: pid },
      { text: fullname },
      { text: enroll_id, alignment: 'center' }
    ])
    idx++
  }
  table.table.body = body
  return {
    columns: [{ width: '10%', text: '' }, table, { width: '10%', text: '' }]
  }
}

const generatePdf = async data => {
  const {
    persons,
    organize_type,
    org_name,
    department,
    ministry,
    authorized_fullname,
    position,
    address_name,
    address_desc,
    cc_desc,
    aa_desc,
    tt_desc,
    tel,
    email,
    document_no
  } = data
  let tb = createPersonTable(persons)
  const option = {
    orientation: 'portrait',
    margin: [60, 50, 30, 0],
    font: 'cordia_upc'
  }
  let offset = 50
  let docDefinition = {
    styles: {
      header1: {
        fontSize: 20,
        bold: true,
        alignment: 'center'
      },
      header2: {
        fontSize: 16,
        bold: true
      },
      header3: {
        fontSize: 14
      }
    },
    defaultStyle: { font: 'cordia_upc', fontSize: 14, lineHeight: 1.5 },
    content: [
      { text: 'แบบลงทะเบียนหน่วยงาน', style: 'header1' },
      '\n',
      {
        style: ['header3', { alignment: 'between' }],
        columns: ['ประเภทหน่วยงาน ' + organize_type]
      },
      '\n',
      { text: 'ข้อมูลหน่วยงาน', style: 'header2' },
      {
        text: `${
          organize_type === 'ภาครัฐ' ? 'ชื่อหน่วยงาน ' : 'ชื่อองค์กร '
        } ${org_name} ${
          organize_type === 'ภาครัฐ' ? `${department} ${ministry}` : ''
        }  `
      },
      '\n',
      { text: 'ข้อมูลที่อยู่ (สถานที่ทำงาน/หน่วยงาน)', style: 'header2' },
      { text: 'ชื่อ สถานที่ทำงาน/หน่วยงาน ' + address_name },

      {
        text: `ที่อยู่ ${address_desc} ${tt_desc} ${cc_desc} ${aa_desc}`
        // text: `ที่อยู่ ${address_desc} ${tt_desc} ${aa_desc} ${cc_desc}` // สลับค่ากันระหว่าง จังหวัด กับ อำเภอ
      },
      { text: 'เบอร์ติดต่อ ' + tel },
      'อีเมล ' + email,
      '\n',
      { text: 'ข้อมูลผู้สมัคร', style: 'header2' },
      'เลขที่เอกสารขออนุญาตใช้งาน ' + document_no,
      '\n',
      tb,
      {
        text: 'ผู้มีอำนาจลงนาม',
        // style: { alignment: 'right' },
        // margin: [0, 150, 45, 0]
        absolutePosition: { x: 420, y: 615 + offset }
      },
      '\n',
      {
        text: '________________________',
        // style: { alignment: 'right' },
        // margin: [0, 0, 25, 0]
        absolutePosition: { x: 390, y: 675 + offset }
      },
      {
        text: `(${authorized_fullname})`,
        // style: { alignment: 'right' },
        // margin: [0, 0, 45, 0]
        absolutePosition: { x: 430, y: 690 + offset }
      },
      {
        text: position,
        // style: { alignment: 'right' },
        // margin: [0, 0, 40, 0]
        absolutePosition: { x: 410, y: 710 + offset }
      }
    ]
  }
  const pdf = await pdfmakeCustom.pdfMake.createPdfToDataUrl(
    docDefinition,
    option
  )
  printDoc(pdf)
}

export { generatePdf }
