export function formatPID(value) {
  const p1 = value.toString().substring(0, 1)
  const p2 = value.toString().substring(1, 5)
  const p3 = value.toString().substring(5, 10)
  const p4 = value.toString().substring(10, 12)
  const p5 = value.toString().substring(12, 13)

  return p1 + `-` + p2 + `-` + p3 + `-` + p4 + `-` + p5
}

export function formatUnit(number) {
  return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
