<template>
  <div id="app">
    <v-app>
      <router-view></router-view>
    </v-app>
  </div>
</template>

<script>
import axios from 'axios'
import { sync } from 'vuex-pathify'
import { api } from '@/core/api'

export default {
  data() {
    return {
      routeName: ''
    }
  },
  computed: {
    userOrganization: sync('userStore/userOrganization'),
    userInfo: sync('userStore/userInfo')
  },
  async created() {
    console.warn('Reloaded')
    try {
      let res = await axios.get('/idp/api/login/v1/info/')
      if (res) {
        const { userInfo, userOrganization, token } = res.data
        this.userInfo = userInfo
        this.userOrganization = userOrganization
        api.defaults.headers.common['Authorization'] = `bearer ${token}`
      }
    } catch (e) {
      console.warn('Not Login')
    }
  },
  watch: {
    $route(to) {
      this.routeName = to.name
    }
  }
}
</script>

<style>
#nprogress .spinner .spinner-icon {
  margin-top: 9px !important;
}

.swal2-styled.swal2-confirm {
  background-color: #3a3d80 !important;
}

.swal2-styled.swal2-cancel {
  background-color: #c13a3a !important;
}

.swal2-html-container {
  margin-top: 0.9em !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.container-page {
  padding: 2rem;
  position: relative;
  min-height: 100%;
  width: 100vw;
  z-index: 0;
}

.label-form {
  width: 15rem;
  margin-right: 1rem;
  padding-top: 0.5rem;
  font-size: 1.2rem;
}

.bg-img {
  position: absolute;
  height: 60vh;
  width: 55vw;
  bottom: 8rem;
  right: 0rem;
  z-index: -1;
}

.form-wrapper {
  width: 70%;
}

@media only screen and (max-width: 600px) {
  .container-page {
    padding: 0.5rem;
  }

  .form-wrapper {
    width: 100%;
  }

  .bg-img {
    height: 50vh;
    width: 50vw;
    bottom: 4rem;
    right: 1rem;
  }
}

.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: rgba(0, 0, 0, 0.7) !important;
}

/* Filed form */
.v-input .v-label {
  line-height: 30px !important;
  height: 30px !important;
}

.v-application--is-ltr .v-text-field__prefix {
  margin-top: 0 !important;
}

.v-text-field--outlined.v-input--dense.v-text-field--outlined>.v-input__control>.v-input__slot {
  min-height: 49px !important;
}

.v-text-field input {
  line-height: 26px !important;
}

.theme--light.v-input input {
  margin-top: 1px !important;
}

.theme--light.v-input textarea {
  margin-top: 14px !important;
}

.v-text-field--outlined.v-input--dense .v-label--active {
  transform: translateY(-22px) scale(0.75) !important;
}

.v-text-field--outlined .v-label--active {
  transform: translateY(-30px) scale(0.75) !important;
}

.v-list-item__content {
  padding: 8px 0 !important;
}

.v-list-item .v-list-item__title,
.v-list-item .v-list-item__subtitle {
  line-height: 2.2 !important;
  font-size: 12pt !important;
}

.v-messages {
  min-height: 20px !important;
}

.v-messages__message {
  line-height: 20px !important;
}

.v-select__selections {
  line-height: 26px !important;
}

.v-input__icon {
  height: 35px !important;
}

.theme--light.v-data-table .v-data-footer .v-input__icon {
  height: 32px !important;
}

/* - End - */
</style>
