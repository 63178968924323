<template>
  <div class="container-page">
    <div class="bg-img bg-officer"></div>
    <!-- Page title -->
    <base-page-header title="ลงทะเบียนเจ้าหน้าที่" subtitle="หน่วยงานภายนอกลงทะเบียนเจ้าหน้าที่"></base-page-header>

    <!-- Form -->
    <div class="form-wrapper mt-10">
      <v-row>
        <v-col cols="12" md="2">
          <p class="label-form">ข้อมูลบุคคล:</p>
        </v-col>

        <v-col cols="12" md="10">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" md="6" xs="12">
                <v-text-field outlined dense v-model="pid" label="เลขประจำตัวประชาชน" :rules="rules"
                  v-mask="'#-####-#####-##-#'"></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-btn x-large color="primary" @click="addPerson()">
            บันทึก
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import ip from 'ip'
import Swal from 'sweetalert2'
import { Officers, General } from '@/core/api'
import { isValidPID } from '@/core/js/validate'
import { get } from 'vuex-pathify'

export default {
  data() {
    return {
      valid: false,
      pid: null,
      rules: [v => !!v || v > 17 || 'กรุณากรอกข้อมูล']
    }
  },
  computed: {
    userInfo: get('userStore/userInfo'),
    userOrganization: get('userStore/userOrganization')
  },
  methods: {
    isValidPID,
    async addPerson() {
      this.$refs.form.validate()
      if (this.$refs.form.validate()) {
        let staffPid = this.pid.split('-').join('')
        let check = this.isValidPID(staffPid)
        if (!check) {
          Swal.fire({
            title: 'บันทึกไม่สำเร็จ',
            icon: 'error',
            text: 'เลขประจำตัวประชาชนไม่ถูกต้อง'
          })
        } else {
          let staffDocumentUrl = '-'
          let role = 2
          const payload = { officers: [{ staffPid, staffDocumentUrl, role }] }
          try {
            await Officers.postRequestOfficer(payload).then(res => {
              if (!res.data.data.createFail) {
                Swal.fire({
                  title: 'บันทึกสำเร็จ',
                  icon: 'success'
                }).then(async () => {
                  let data = {
                    requestPid: Number(this.userInfo.pid),
                    orgID: this.userOrganization.organizeID,
                    ipAddress: ip.address(),
                    detail:
                      'เพิ่มผู้ใช้งานในหน่วยงาน ' +
                      this.userOrganization.organizeID
                  }
                  try {
                    await General.postActivityLogs(data)
                  } catch (err) {
                    console.error(err)
                  }

                  this.$router.push('/home')
                })
              } else {
                Swal.fire({
                  title: 'บันทึกไม่สำเร็จ',
                  icon: 'error',
                  text: 'ไม่สามารถบันทึก ' + res.data.data.createFail + ' ได้'
                })
              }
            })
          } catch (e) {
            Swal.fire({
              title: 'บันทึกไม่สำเร็จ',
              icon: 'error',
              // text: 'ไม่พบเลขประจำตัวประชาชนในระบบ'
              text: e.message
            })
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bg-officer {
  background: url(~@/assets/org-add-emp.png) center;
  background-size: contain;
  background-repeat: no-repeat;
}

.col-12 {
  padding: 5px 12px 0 12px;
}
</style>
