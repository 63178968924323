const isValidPID = pid => {
  let tmpPid = pid.replace(/-/gi, '')

  if (parseInt(tmpPid.substring(0, 1)) === 9) {
    return false
  }
  if (tmpPid.length !== 13) {
    return false
  }

  let sum = 0
  for (let i = 0; i < 12; i++) {
    sum += parseFloat(tmpPid.charAt(i)) * (13 - i)
  }
  return (11 - (sum % 11)) % 10 == parseFloat(tmpPid.charAt(12))
}

export { isValidPID }
