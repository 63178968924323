const Browser = {
  isIE: () => {
    return (
      navigator.userAgent.indexOf('MSIE') > -1 ||
      navigator.userAgent.indexOf('Trident/') > -1
    )
  },
  isChrome: () => {
    return navigator.userAgent.indexOf('Chrome') > -1
  },
  isFirefox: () => {
    return navigator.userAgent.indexOf('Firefox') > -1
  }
}

const dataURItoDataURL = (dataURI, blobOption) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  let byteString = atob(dataURI.split(',')[1])

  // separate out the mime component
  // var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  let ab = new ArrayBuffer(byteString.length)
  let ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  // write the ArrayBuffer to a blob, and you're done
  let bb = new Blob([ab], blobOption)
  return (window.URL || window.webkitURL).createObjectURL(bb)
}

export default pdfURI => {
  let dataURL = dataURItoDataURL(pdfURI, { type: 'application/pdf' })

  // window.open(dataURL, '_blank')

  if (!Browser.isChrome()) {
    window.open(dataURL, '_blank')
  } else {
    if (document.getElementById('pdfPrint'))
      document.getElementById('pdfPrint').remove()

    let printFrame = document.createElement('iframe')
    printFrame.setAttribute(
      'style',
      'padding: 0px; margin: 0px; width:0; height:0; border:0;'
    )
    printFrame.setAttribute('id', 'pdfPrint')
    printFrame.setAttribute('src', dataURL)
    window.document.getElementsByTagName('body')[0].appendChild(printFrame)
    printFrame.setAttribute.sandbox =
      'allow-forms allow-scripts allow-same-origin'
    // ! let iframeElement = document.getElementById("pdfPrint")
    printFrame.onload = () => {
      printFrame.focus()
      printFrame.contentWindow.print()
    }
  }
}
