<template>
  <div class="wrapper">
    <div class="d-flex" :class="appType == 'sandbox' ? 'login-sandbox' : 'login-main'">
      <v-btn icon :ripple="false" color="pink" id="no-background-hover" height="150px" @click="showQR()">
        <v-hover>
          <v-img slot-scope="{ hover }" v-if="hover" :src="images.logoThaID2" width="120px" height="auto"></v-img>
          <v-img v-else :src="images.logoThaID1" width="120px" height="auto"></v-img>
        </v-hover>
      </v-btn>
      <div class="font-login">
        Login By ThaID
      </div>
      <div class="image-logo">
        <img :src="images.logo" alt="dopa" width="140px" height="auto" />
      </div>
      <div class="image-dopa">
        <img :src="images.dopa" alt="dopa" width="50px" height="auto" />
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import axios from 'axios'
import { api } from '@/core/api'
import { sync } from 'vuex-pathify'

export default {
  name: 'Login',
  data() {
    return {
      appType: null,
      images: {
        logoThaID1: require('../assets/ThaiD1.png'),
        logoThaID2: require('../assets/ThaiD2.png'),
        dopa: require('../assets/DOPA.png'),
        logo: require('../assets/Defund-Logo-Eng_0.png')
      }
    }
  },
  async created() {
    this.appType = localStorage.getItem('type')
    sessionStorage.removeItem('app')

    try {
      let res = await axios.get('/idp/api/login/v1/info/')
      if (res) {
        const { userInfo, userOrganization, token } = res.data
        this.userInfo = userInfo
        this.userOrganization = userOrganization
        api.defaults.headers.common['Authorization'] = `bearer ${token}`
        this.$router.push({ name: 'SelectOrganization' })
      }
    } catch (e) {
      localStorage.removeItem('logsLogin')
      console.warn('Not Login')
    }
  },
  computed: {
    userOrganization: sync('userStore/userOrganization'),
    userInfo: sync('userStore/userInfo'),
  },
  methods: {
    async showQR() {
      try {
        let res = await axios.get('/idp/api/login/v1/oauth2/')
        window.location.href = res.data.redirectUrl
      } catch (err) {
        Swal.fire({
          title: 'server ขัดข้อง',
          icon: 'error'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
  width: 100%;
}

#no-background-hover::before {
  background-color: transparent !important;
}

.font-login {
  font-weight: 600;
  text-align: center;
  font-size: 1.2em;
}

.login-main {
  background: url(~@/assets/login-bg.png);
  background-position: center;
  background-size: auto 85%;
  image-rendering: crisp-edges;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-sandbox {
  background: url(~@/assets/sandbox-bg.png);
  background-position: center;
  background-size: 100%;
  image-rendering: crisp-edges;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image-dopa {
  position: absolute;
  bottom: 0;
  right: 170px;
}

.image-logo {
  position: absolute;
  bottom: 0;
  right: 10px;
}
</style>
