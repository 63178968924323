import { api } from './index'

const prefix = '/idp/api/v1/applications'
const prefixFVS = '/idp/api/v1/fvsapplications'

export default {
  getApplications(instance) {
    console.log(`##### getApplications #####`)
    return api.get(`${prefix}/`, {
      headers: {
        'data-instance': instance
      }
    })
  },
  getApplicationByID(id, instance) {
    console.log(`##### getApplicationByID: ${id} #####`)
    return api.get(`${prefix}/${id}`, {
      headers: {
        'data-instance': instance
      }
    })
  },
  getApplicationConsent(id, instance) {
    console.log(`##### getApplicationConsent: ${id} #####`)
    return api.get(`${prefix}/consent/${id}`, {
      headers: {
        'data-instance': instance
      }
    })
  },
  postApplications(data, instance) {
    console.log(`##### postApplications #####`)
    return api.post(`${prefix}`, data, {
      headers: {
        'data-instance': instance
      }
    })
  },
  updateApplication(id, appInfo, instance) {
    console.log(`##### updateApplication: ${id} #####`)
    return api.put(`${prefix}/${id}/data`, appInfo, {
      headers: {
        'data-instance': instance
      }
    })
  },
  updateApplicationAdmin(id, appInfo, instance) {
    console.log(`##### updateApplication: ${id} #####`)
    return api.put(`${prefix}/${id}/admin`, appInfo, {
      headers: {
        'data-instance': instance
      }
    })
  },
  deleteApplication(id, userPID, OrgID, instance) {
    console.log(`##### deleteApplication: ${id} #####`)
    return api.delete(`${prefix}/delete/${id}/${userPID}/${OrgID}`, {
      headers: {
        'data-instance': instance
      }
    })
  },
  getPendingCredential(id, instance) {
    console.log(`##### getPendingCredential: ${id} #####`)
    return api.get(`${prefix}/pending-credential/${id}`, {
      headers: {
        'data-instance': instance
      }
    })
  },
  postCreateApplicationKey(id, userID, instance) {
    console.log(`##### postCreateApplicationKey: ${id} #####`)
    return api.post(`${prefix}/${id}/${userID}/generate-credential`, {
      headers: {
        'data-instance': instance
      }
    })
  },
  getConsentStatusByTxID(txID, instance) {
    console.log(`##### getConsentStatusByTxID #####`)
    return api.get(`${prefix}/consent-tx/${txID}`, {
      headers: {
        'data-instance': instance
      }
    })
  },
  getApplicationKey(id, instance) {
    console.log(`##### getApplicationsKey: ${id} #####`)
    return api.get(`${prefix}/get-app-credential/${id}`, {
      headers: {
        'data-instance': instance
      }
    })
  },
  // FVS -----------------------------------------------
  getApplicationsFVS() {
    console.log(`##### getApplicationsFVS #####`)
    return api.get(`${prefixFVS}/`)
  },
  postApplicationsFVS(data) {
    console.log(`##### postApplicationsFVS #####`)
    return api.post(`${prefixFVS}/`, data)
  },
  updateApplicationFVS(id, appInfo) {
    console.log(`##### updateApplicationFVS: ${id} #####`)
    return api.put(`${prefixFVS}/${id}/data`, appInfo)
  },
  deleteApplicationFVS(id, userPID, OrgID) {
    console.log(`##### deleteApplicationFVS: ${id} #####`)
    let path = `${prefixFVS}/delete/${id}/${userPID}/${OrgID}`
    return api.delete(path)
  },
  getPendingCredentialFVS(id) {
    console.log(`##### getPendingCredentialFVS: ${id} #####`)
    return api.get(`${prefixFVS}/pending-credential/${id}`)
  },
  postCreateApplicationKeyFVS(id, userID) {
    console.log(`##### postCreateApplicationKeyFVS: ${id} #####`)
    return api.post(`${prefixFVS}/${id}/${userID}/generate-credential`)
  },
  getApplicationConsentFVS(id) {
    console.log(`##### getApplicationConsentFVS: ${id} #####`)
    return api.get(`/api/fvs/stat/v1/party/application/${id}`)
  },
  getConsentStatusByTxIDfvs(txID) {
    console.log(`##### getConsentStatusByTxIDfvs #####`)
    return api.get(`${prefixFVS}/consent-tx/${txID}`)
  },
  getApplicationKeyFVS(id) {
    console.log(`##### getApplicationKeyFVS: ${id} #####`)
    return api.get(`${prefixFVS}/get-app-credential/${id}`)
  }
}
