<template>
  <div>
    <v-card height="200px" class="card-proj-component">
      <div>
        <v-img :src="logo" class="card-proj-component-image" alt="logoApp" :aspect-ratio="isMobile == false ? `3` : `2`"
          contain></v-img>
      </div>
      <div class="card-proj-component-text mt-2">
        <h2>
          <u>{{ title }}</u>
        </h2>
        <h4>{{ subtitle }}</h4>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: [String, Number]
    },
    subtitle: {
      type: [String, Number]
    },
    logo: {
      type: String,
      default:
        'https://upload.wikimedia.org/wikipedia/commons/f/fb/Wikisource-logo.png'
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    }
  }
}
</script>

<style lang="scss" scoped>
.card-proj-component {
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  color: #3a3d80;
  overflow: hidden;
  position: relative;
  background-color: rgba($color: #ffffff, $alpha: 0.95);

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }

  &-image {
    width: 13vw;
    opacity: 1;
  }

  &-text {
    text-align: center;
  }

  u {
    font-size: 1.4rem;
    text-decoration: none;
  }

  h4 {
    font-size: 1rem;
  }
}
</style>
