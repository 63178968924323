import { render, staticRenderFns } from "./RegisterApplication.vue?vue&type=template&id=048e2cc7&scoped=true&"
import script from "./RegisterApplication.vue?vue&type=script&lang=js&"
export * from "./RegisterApplication.vue?vue&type=script&lang=js&"
import style0 from "./RegisterApplication.vue?vue&type=style&index=0&id=048e2cc7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "048e2cc7",
  null
  
)

export default component.exports