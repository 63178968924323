<template>
  <div class="container-page">
    <div class="bg-img bg-officer"></div>
    <!-- Page title -->
    <base-page-header title="จัดการสิทธิแอปพลิเคชัน" subtitle="หน่วยงานภายนอกลงทะเบียนเจ้าหน้าที่"></base-page-header>

    <!-- Form -->
    <div class="form-wrapper mt-10">
      <v-row>
        <v-col cols="12" md="12">
          <v-card>
            <v-card-title>
              <span class="primary--text font-weight-bold">ค้นหาผู้ดูแล</span>
              <v-spacer></v-spacer>
              <v-text-field outlined dense hide-details v-model="search" prepend-inner-icon="mdi-magnify"
                class="search-box mt-0 pt-0"></v-text-field>
            </v-card-title>
            <v-data-table class="elevation-1" :loading="applications == []" :items-per-page="10" :headers="headers"
              :items="applications" :search="search" :headerProps="{ sortByText: 'เรียงตาม' }" :footer-props="{
                'items-per-page-text': 'แสดง',
                'items-per-page-options': [10, 20, 30, -1],
                'items-per-page-all-text': 'ทั้งหมด'
              }">
              <template v-slot:item.applicationName="{ item }">
                <span class="primary--text font-weight-bold">{{
                  item.applicationName
                }}</span>
              </template>
              <template v-slot:item.adminPid="{ item }">
                <span class="primary--text">
                  {{ formatPid(item.adminPid) }}
                </span>
              </template>
              <!-- <template v-slot:item.controls="props">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="orange"
                  @click="openDialog(props.item)"
                >
                  <v-icon dark>mdi-circle-edit-outline</v-icon>
                </v-btn>
              </template> -->
              <template v-slot:item.edit="{ item }">
                <v-btn icon color="orange" @click="openDialog(item)">
                  <v-icon color="orange">mdi-circle-edit-outline</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="dialog" persistent max-width="690">
      <v-card>
        <v-card-title class="text-h5 primary lighten-1 white--text">
          จัดการสิทธิแอปพลิเคชัน
        </v-card-title>
        <v-card-text>
          <v-row class="mt-3">
            <v-col cols="12" md="12">
              <v-combobox outlined dense small-chips item-text="fullname" item-value="pid" return-object hide-selected
                v-model="cmbOfficer" label="เจ้าหน้าที่" :items="officersBody" :hide-no-data="!search"
                :search-input.sync="search" :rules="fieldRules" clearable />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialog = false">
            ยกเลิก
          </v-btn>
          <v-btn color="green darken-1" text :disabled="disConfirm" @click="updOfficer">
            ยืนยัน
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { Applications } from '@/core/api'
import { Officers } from '@/core/api'
import { formatPID } from '@/core/js/format'
import { isValidPID } from '@/core/js/validate'
import { get } from 'vuex-pathify'

export default {
  name: 'ManagePrivilege',
  data() {
    return {
      dialog: false,
      disConfirm: true,
      cmbOfficer: null,
      fieldRules: [v => !!v || 'กรุณากรอกข้อมูล'],
      pagination: {
        sortBy: 'sequence',
        page: 1,
        rowsPerPage: 5
      },
      rowsPerPageItem: [
        { text: '5 รายการ', value: 5 },
        { text: '10 รายการ', value: 10 },
        { text: '25 รายการ', value: 25 },
        { text: 'ทั้งหมด', value: -1 }
      ],
      search: '',
      headers: [
        // {
        //   text: 'ลำดับ',
        //   align: 'start',
        //   value: 'no',
        // },
        {
          text: 'ชื่อแอปพลิเคชัน',
          value: 'applicationName',
          sortable: false,
          align: 'center'
        },
        {
          text: 'ผู้ดูแล',
          value: 'adminPid',
          sortable: false,
          align: 'center'
        },
        {
          text: 'แก้ไข',
          sortable: false,
          value: 'edit',
          align: 'center'
        }
      ],
      appList: [
        {
          id: 1,
          name: 'App 1',
          admin: 'นายทดสอบ 1'
        },
        {
          id: 2,
          name: 'App 2',
          admin: 'นายทดสอบ 2'
        },
        {
          id: 3,
          name: 'App 3',
          admin: 'นายทดสอบ 3'
        }
      ],
      applications: [],
      valid: false,
      pid: null,
      officersBody: [],
      appSelect: null,
      rules: [v => !!v || v > 17 || 'กรุณากรอกข้อมูล']
    }
  },
  computed: {
    userInfo: get('userStore/userInfo'),
    userOrganization: get('userStore/userOrganization')
  },
  async mounted() {
    await this.getApp()
    await this.getOfficer()
  },
  watch: {
    cmbOfficer(val) {
      this.disConfirm = true
      if (val) this.disConfirm = false
    }
  },
  methods: {
    isValidPID,
    formatPid: formatPID,
    async getApp() {
      this.applications = []
      try {
        let type = localStorage.getItem('type')
        type = type === 'main' ? 'idp' : 'sandbox'
        let apps = await Applications.getApplications(type)
        apps.data.data.map(r => {
          if (r.organizeID === this.userOrganization.organizeID) {
            if (r.active == 1) {
              this.applications.push(r)
            }
          }
        })
      } catch (err) {
        console.error(err)
      }
    },
    async getOfficer() {
      this.officersBody = []
      const officer = await Officers.getOfficers()
      officer.data.map(r => {
        if (
          parseInt(r.organizeID) == parseInt(this.userOrganization.organizeID)
        ) {
          if (r.allow === 1 && r.active === 1) this.officersBody.push(r)
        }
      })
    },
    async updOfficer() {
      try {
        let type = localStorage.getItem('type')
        type = type === 'main' ? 'idp' : 'sandbox'
        const updOfficer = await Applications.updateApplicationAdmin(
          this.appSelect.applicationID,
          {
            adminPid: this.cmbOfficer.pid
          },
          type
        )
        console.log(updOfficer)
        if (!updOfficer) {
          return await Swal.fire({
            title: 'บันทึกไม่สำเร็จ',
            icon: 'warning',
            text: 'Error : ' + updOfficer
          })
        }

        // .then(async () => {
        await Swal.fire({
          title: 'บันทึกสำเร็จ',
          icon: 'success'
        })
        this.dialog = false
        await this.getApp()
        // })
      } catch (err) {
        console.error(err)
        Swal.fire({
          title: 'บันทึกไม่สำเร็จ',
          icon: 'error',
          text: err.message
        })
      }
    },
    async openDialog(app) {
      this.cmbOfficer = null
      this.appSelect = null
      this.appSelect = app
      this.dialog = true
    },
    getBG(no) {
      if (no % 2 === 0) return '#F5F5F5'
      else return '#ffffff'
    }
  }
}
</script>

<style lang="scss" scoped>
.bg-officer {
  background: url(~@/assets/org-add-emp.png) center;
  background-size: contain;
  background-repeat: no-repeat;
}

.col-12 {
  padding: 5px 12px 0 12px;
}

.search-box {
  width: 0px !important;
}

.v-data-table::v-deep th {
  font-size: 1rem !important;
  font-weight: 400 !important;
  color: #f6f6fa !important;
  background: #3a3d80;
}

.v-data-table::v-deep tbody tr:nth-of-type(even) {
  background-color: #ecf0fe;
}

.v-data-table::v-deep th i {
  color: rgba(255, 255, 255, 0.8) !important;
}

.v-data-table::v-deep th i:hover {
  color: rgba(255, 255, 255, 1) !important;
}

.v-data-table::v-deep .rounded-tl-lg {
  border-top-left-radius: 4px !important;
}

.v-data-table::v-deep .rounded-tr-lg {
  border-top-right-radius: 4px !important;
}
</style>
