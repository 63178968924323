<template>
  <div>
    <v-app-bar app absolute class="nav-background" elevation="3" v-if="typeEnvi === 'main'">
      <div class="title-composer">
        <div class="title-composer__brand-img"></div>
        <h2 class="title-composer__title">
          <span>{{ title }}</span>
        </h2>
      </div>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click="drawer = !drawer" v-if="$route.path != '/login'"></v-app-bar-nav-icon>
    </v-app-bar>
    <v-app-bar app absolute class="nav-background-sandbox" elevation="3" v-if="typeEnvi === 'sandbox'">
      <div class="title-composer">
        <div class="title-composer__brand-img-sandbox"></div>
        <h2 class="title-composer__title">
          <span>{{ title }}</span>
          <span> - [SANDBOX]</span>
        </h2>
      </div>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click="drawer = !drawer" v-if="$route.path != '/login'"></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer right absolute temporary v-model="drawer">
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item @click="toOrgList()" v-if="!isSelectOrg">
            <v-list-item-icon>
              <v-icon>mdi-office-building</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{
              userOrganization === null ? '' : userOrganization.organizeName
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="toEnvList()"
            v-if="$route.path != '/org' && $route.path != '/env' && typeEnvi === 'sandbox'">
            <v-list-item-icon>
              <v-icon>mdi-webpack</v-icon>
            </v-list-item-icon>
            <v-list-item-title>SANDBOX</v-list-item-title>
          </v-list-item>
          <v-list-item @click="toEnvList()" v-if="$route.path != '/org' && $route.path != '/env' && typeEnvi === 'main'">
            <v-list-item-icon>
              <v-icon>mdi-webpack</v-icon>
            </v-list-item-icon>
            <v-list-item-title>MAIN</v-list-item-title>
          </v-list-item>
          <v-list-item @click="toAppList()"
            v-if="$route.path.includes('application') || $route.path.includes('privilege') || $route.path == '/officer'">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>กลับ</v-list-item-title>
          </v-list-item>
          <v-list-item @click="openManual()"
            v-if="userInfo !== null && $route.path != '/env' && $route.path != '/org' && $route.path != '/register/officer'">
            <v-list-item-icon>
              <v-icon>mdi-book-open-page-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-title>คู่มือพัฒนาระบบ</v-list-item-title>
          </v-list-item>
          <v-list-item @click="signOut()" v-if="userInfo !== null">
            <v-list-item-icon>
              <v-icon>mdi-login</v-icon>
            </v-list-item-icon>
            <v-list-item-title>ออกจากระบบ</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { get, sync } from 'vuex-pathify'
import Swal from 'sweetalert2'
import axios from 'axios'
import { api, General } from '@/core/api'
import ip from 'ip'

export default {
  name: "headerBar",
  data() {
    return {
      pdfLink: require("@/manual/manualDev-V1.0.0.pdf"),
      pdfLinkSandbox: require("@/manual/manualDevSandbox-V0.1.0.pdf"),
      drawer: false,
      title: 'ระบบจัดการแอปพลิเคชัน',
      typeMain: true,
      isSelectOrg: true,
      isSelectPage: true,
    }
  },
  computed: {
    typeEnvi: sync('userStore/typeEnvi'),
    userOrganization: get('userStore/userOrganization'),
    userInfo: get('userStore/userInfo'),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  created() {
    this.typeMain = true
    this.title = this.$route.meta.title
    this.isSelectPage = this.$route.path == '/home' || this.$route.path == '/org'
    this.isSelectOrg =
      this.$route.path == '/org' || this.$route.path == '/register/officer'
    if (this.userInfo) {
      if (this.userInfo.list.length === 1) return (this.isSelectOrg = true)
    }
    let type = localStorage.getItem('type')
    this.typeEnvi = type
  },
  watch: {
    $route(to) {
      let type = localStorage.getItem('type')
      this.typeEnvi = type
      this.title = to.meta.title
      this.isSelectPage = to.path == '/home' || to.path == '/org'
      this.isSelectOrg = to.path == '/org'
      if (this.userInfo) {
        if (this.userInfo.list.length === 1) return (this.isSelectOrg = true)
      }
    },
  },
  methods: {
    async signOut() {
      Swal.fire({
        title: 'ออกจากระบบ',
        text: 'กรุณายืนยันการออกจากระบบ',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก'
      }).then(async result => {
        sessionStorage.removeItem('app')
        localStorage.removeItem('type')

        if (result.value) {
          let data = {
            requestPid: Number(this.userInfo.pid),
            orgID: this.userOrganization === null ? 0 : this.userOrganization.organizeID,
            ipAddress: ip.address(),
            detail: 'ออกจากระบบ'
          }
          try {
            await General.postActivityLogs(data)
          } catch (err) {
            console.error(err)
          }

          await axios
            .get(`/idp/api/login/v1/oauth2/logout/`)
            .then(() => {
              api.defaults.headers.common['Authorization'] = ''
              window.location.href = window.location.origin + "/idp/"
            })
            .catch(function (error) {
              console.log(error)
              window.location.href = window.location.origin + "/idp/"
            })
            .then(() => {
              window.location.href = window.location.origin + "/idp/"
            })
        }
      })
    },
    toAppList() {
      if (this.$route.name != 'ApplicationManage') {
        this.$router.push({ name: 'ApplicationManage' })
      }
    },
    toOrgList() {
      localStorage.setItem('type', 'main')
      this.typeEnvi = 'main'
      if (this.$route.name != 'SelectOrganization') {
        this.$router.push({ name: 'SelectOrganization' })
      }
    },
    toEnvList() {
      if (this.$route.name != 'SelectEnvironment') {
        this.$router.push({ name: 'SelectEnvironment' })
      }
    },
    openManual() {
      if (this.typeEnvi === 'sandbox') {
        window.open(this.pdfLinkSandbox, "_blank")
      } else {
        window.open(this.pdfLink, "_blank")
      }
    }
  }
}
</script>

<style lang="scss">
.nav-background {
  background: linear-gradient(270.02deg, #181b54 0.02%, #3a3d80 90.54%);
}

.nav-background-sandbox {
  background: linear-gradient(270.02deg, #e5830f 0.02%, #f89929 90.54%);
}

.v-btn--icon.v-size--default .v-icon,
.v-btn--fab.v-size--default .v-icon {
  color: #ffffff;
}

.router-link-active {
  color: #f6f6fa !important;

  &::before {
    content: '';
    position: absolute !important;
    bottom: 0 !important;
    left: 0.2rem !important;
    width: 90% !important;
    height: 0.3rem !important;
    background: #f6f6fa !important;
    transition: all 0.4s !important;
  }
}

.nav-composer {
  padding: 0 1rem;

  span {
    padding: 0.5rem 0.2rem;
    position: relative;
    transition: all 0.3s;
    color: #ebebeb;

    &:hover {
      cursor: pointer;
      color: #ebebeb;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0.2rem;
      width: 0;
      height: 0.3rem;
      background: #ebebeb;
      transition: all 0.4s;
    }

    &:hover::before {
      width: 90%;
    }
  }
}

.title-composer {
  padding: 0 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &__title {
    color: #f6f6fa;
    letter-spacing: 2px;
    font-size: 1.3rem;
  }

  &__brand-img {
    width: 2rem;
    height: 2rem;
    background: url(~@/assets/logo.png);
    margin-right: 1rem;
    background-size: cover;
  }

  &__brand-img-sandbox {
    width: 2rem;
    height: 2rem;
    background: url(~@/assets/logos.png);
    margin-right: 1rem;
    background-size: cover;
  }
}

@media only screen and (max-width: 600px) {
  .title-composer {
    &__title {
      font-size: 1rem;
      letter-spacing: 0;
    }
  }
}

.icon-app:hover {
  color: #bebebe !important;
}
</style>
