<template>
  <div class="container-page bg-app">
    <!-- Page title -->
    <base-page-header title="แก้ไขข้อมูลแอปพลิเคชัน"
      subtitle="หน่วยงานภายนอกลงทะเบียนขอแก้ไขข้อมูลแอปพลิเคชัน"></base-page-header>

    <!-- Form -->
    <div class="form-wrapper">
      <v-form ref="entryForm" v-model="isValidForm" lazy-validation>
        <v-row class="mt-5">
          <v-col cols="12" md="11">
            <!-- Organize Type -->
            <v-row d-flex justify="start" align="start">
              <v-col cols="6" md="3">
                <p>ประเภทของหน่วยงาน :</p>
              </v-col>
              <v-col cols="6" md="3">
                <p>{{ userOrganization.organizeType }}</p>
              </v-col>
            </v-row>

            <!-- Application Type -->
            <v-row d-flex justify="start" align="start">
              <v-col cols="6" md="3" class="mt-4">
                <p>ประเภทแอปพลิเคชัน :</p>
              </v-col>
              <v-col cols="6" md="6">
                <v-radio-group row v-model="formRole">
                  <v-radio label="RP" value="1"></v-radio>
                  <!-- <v-radio label="AS" value="2"></v-radio>
                  <v-radio label="RP และ AS" value="3"></v-radio> -->
                </v-radio-group>
              </v-col>
            </v-row>

            <!-- Section 1: Organize Info -->
            <v-row d-flex justify="start" align="start">
              <v-col cols="6" md="3">
                <p>ข้อมูลแอปพลิเคชัน :</p>
              </v-col>
              <v-col cols="12" md="9">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field outlined dense v-model="appName" label="ชื่อแอปพลิเคชันภาษาไทย" class="mb-5"
                      :rules="field100Rules" counter="100" @focus="checkAppNameUnique()" />
                    <v-text-field outlined dense v-model="appEnName"
                      label="ชื่อแอปพลิเคชันภาษาอังกฤษ (ต้องเป็นตัวพิมพ์เล็กทั้งหมด)" class="mb-5" :rules="field50Rules"
                      counter="50" @focus="unfocusAppEnName = false" :loading="unfocusAppEnName"
                      :color="appEnNameIsUnique ? 'success' : ''" :append-icon="appEnNameIsUnique ? 'mdi-checkbox-marked-circle' : ''
                        " />
                    <!-- <v-text-field
                      outlined
                      dense
                      v-model="appAbbreviate"
                      label="ชื่อย่อแอปพลิเคชัน"
                      :rules="field5_10Rules"
                      counter="10"
                      hint="ไม่สามารถเปลี่ยนแปลงได้ (5-10 ตัวอักษร)"
                      @focus="unfocusAppAbbreviate = false"
                      :loading="unfocusAppAbbreviate"
                      :color="appAbbreviateIsUnique ? 'success' : ''"
                      :append-icon="
                        appAbbreviateIsUnique
                          ? 'mdi-checkbox-marked-circle'
                          : ''
                      "
                    /> -->
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-textarea outlined dense v-model="purpose" label="วัตถุประสงค์" :rules="field255Rules" counter="255"
                      rows="9" @focus="checkAppNameUnique()"></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider></v-divider>

            <!-- Section 2: Important Info  -->
            <v-row class="mt-2" d-flex justify="start" align="start">
              <v-col cols="6" md="3">
                <p>ข้อมูลสำคัญ :</p>
              </v-col>
              <v-col cols="12" md="9">
                <v-row>
                  <v-col cols="12" md="6" xs="12" v-if="formRole != '2'">
                    <v-text-field outlined dense v-model="callbackUrl" label="Callback" :rules="field300Rules"
                      counter="300" placeholder="example.com" hint="https://example.com/" @focus="checkAppNameUnique()" />
                  </v-col>
                  <v-col cols="12" md="6" xs="12">
                    <v-combobox outlined dense multiple small-chips hide-selected v-model="model" label="IP Address"
                      :items="items" :hide-no-data="!search" :search-input.sync="search" :rules="fieldRules"
                      @focus="checkAppNameUnique()">
                      <template v-slot:no-data>
                        <v-list-item>
                          <span class="subheading">เพิ่ม</span>
                          <v-chip label small :color="`${colors[nonce - 1]} lighten-3`">
                            {{ search }}
                          </v-chip>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{
                        attrs,
                        item,
                        parent,
                        selected,
                        index
                      }">
                        <v-chip label small v-if="item === Object(item)" v-bind="attrs" :color="`${item.color} lighten-3`"
                          :input-value="selected">
                          <span class="pr-2">{{ item.text }}</span>
                          <v-icon small @click="parent.selectItem(item), remove(index)">
                            mdi-close
                          </v-icon>
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field outlined dense v-model="logoImg" label="URL Logo" :rules="field255Rules" counter="255"
                      @focus="checkAppNameUnique()" />
                  </v-col>
                  <v-col cols="12" md="6">
                    <img v-if="logoImg" :src="logoImg" alt=" " width="150" @error="onImgLoadError"
                      @load="onImgLoadSuccess" />
                  </v-col>
                  <v-col cols="12" md="6" xs="12">
                    <v-text-field outlined dense v-model="policyUrl" label="Policy" :rules="field255Rules" counter="255"
                      prefix="https://" placeholder="example.com" hint="https://example.com/"
                      @focus="checkAppNameUnique()" />
                  </v-col>
                  <v-col cols="12" md="6" xs="12">
                    <v-text-field outlined dense v-model="termsOfUseUrl" label="Terms of Use" :rules="field255Rules"
                      counter="255" prefix="https://" placeholder="example.com" hint="https://example.com/"
                      @focus="checkAppNameUnique()" />
                  </v-col>
                  <v-col cols="12" md="12" xs="12" v-if="false">
                    <v-text-field outlined dense v-model="webHook" label="Webhook" :rules="field128NotRequireRules"
                      counter="128" prefix="https://" placeholder="webhook.com" hint="https://webhook.com/"
                      @focus="checkAppNameUnique()" />
                  </v-col>
                  <div v-if="false">
                    <template v-for="(n, idx) in listHeaders">
                      <v-col :key="'head' + n" cols="12" md="5" xs="12">
                        <v-text-field outlined dense v-model="headersKeyCollections[idx]" prefix="Header Key :"
                          @focus="checkAppNameUnique()" />
                      </v-col>
                      <v-col :key="n" cols="12" md="5" xs="12">
                        <v-text-field outlined dense v-model="headersValCollections[idx]" prefix="Header Value :"
                          @focus="checkAppNameUnique()" />
                      </v-col>
                    </template>
                    <v-col cols="2" md="2">
                      <v-layout>
                        <v-flex class="mr-1">
                          <v-btn color="success" class="btn-webhook" @click="headerListValue">
                            +
                          </v-btn>
                        </v-flex>
                        <v-flex>
                          <v-btn color="error" class="btn-webhook" @click="rmvHeader(listHeaders - 1)"
                            v-if="listHeaders > 1">
                            -
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-col>
                  </div>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-2"></v-divider>

            <!-- Section 3: Needed Info -->
            <v-row d-flex justify="start" align="start" class="mt-1"
              v-if="!formRole || formRole == '1' || formRole == '3'">
              <v-col cols="6" md="3" class="mt-5">
                <p>ข้อมูลที่ต้องการใช้ :</p>
              </v-col>
              <v-col cols="12" md="9">
                <v-row>
                  <template v-for="(list, idx) in lists">
                    <v-col :key="idx" cols="6" md="4">
                      <v-checkbox :readonly="neededInfoScopesGet.includes(list.value)" v-model="neededInfoScopes"
                        :label="list.text" :value="list.value" @click="dialogScopes(list.value)"></v-checkbox>
                    </v-col>
                  </template>
                </v-row>
              </v-col>
            </v-row>

            <!-- Section 4-1: Specification Document -->
            <v-row d-flex justify="start" align="start" v-if="formRole == '2' || formRole == '3'">
              <v-col cols="6" md="3" class="mt-5">
                <p>เอกสารการใช้งาน :</p>
              </v-col>
              <v-col cols="12" md="9" class="mt-3">
                <v-row>
                  <v-col cols="12" md="6" xs="12">
                    <v-text-field outlined dense v-model="apiSpecURL" label="Document URL (API Specification)"
                      @focus="checkAppNameUnique()"></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!-- Section 4-2: AS Needed -->
            <div v-if="false">
              <v-row d-flex justify="start" align="start" v-if="!formRole || formRole == '1' || formRole == '3'">
                <v-col cols="6" md="3" class="mt-5">
                  <p>ข้อมูล AS ที่ต้องการใช้งาน :</p>
                </v-col>
                <v-col cols="12" md="9" class="mt-3">
                  <v-row>
                    <v-col cols="12">
                      <v-data-table v-model="asSelected" :show-select="asList.length > 0"
                        :hide-default-footer="asList.length < 1" :headers="headerAS" :items="asList" item-key="name"
                        class="elevation-1 rounded-t-xl rounded-b-lg" :headerProps="{ sortByText: 'เรียงตาม' }"
                        :footer-props="{
                          'items-per-page-text': 'แสดง',
                          'items-per-page-options': [10, 20, 30, -1],
                          'items-per-page-all-text': 'ทั้งหมด'
                        }">
                        <template v-slot:no-data>
                          <v-layout>
                            <v-flex xs12 class="secondary--text">ไม่มีข้อมูล</v-flex>
                          </v-layout>
                        </template>
                        <template #footer.page-text="props">
                          {{ props.pageStart }} -
                          {{ props.pageStop }} จากทั้งหมด
                          {{ props.itemsLength }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-8">
          <v-col cols="6" md="3">
            <v-btn x-large class="width-100 white--text" color="primaryDarken1" :disabled="isValidForm == false"
              @click="submit()">
              บันทึก
            </v-btn>
          </v-col>
          <v-col cols="6" md="3">
            <v-btn x-large outlined class="width-100 primaryDarken1--text" color="primaryDarken1" @click="back()">
              <strong>ย้อนกลับ</strong>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import ip from 'ip'
import Swal from 'sweetalert2'
import { get } from 'vuex-pathify'
import { Applications, General } from '../../core/api'
import { errorHandler } from '../../core/js/handleError'

export default {
  name: 'ApplicationEdit',
  data() {
    return {
      fieldRules: [v => !!v || 'กรุณากรอกข้อมูล'],
      field100Rules: [
        v => !!v || 'กรุณากรอกข้อมูล',
        v => (v && v.length <= 100) || 'ข้อมูลต้องไม่เกิน 100 ตัวอักษร'
      ],
      field255Rules: [
        v => !!v || 'กรุณากรอกข้อมูล',
        v => (v && v.length <= 255) || 'ข้อมูลต้องไม่เกิน 255 ตัวอักษร'
      ],
      field50Rules: [
        v => !!v || 'กรุณากรอกข้อมูล',
        v => (v && v.length <= 50) || 'ข้อมูลต้องไม่เกิน 50 ตัวอักษร'
      ],
      field128NotRequireRules: [
        v => v.length <= 128 || 'ข้อมูลต้องไม่เกิน 128 ตัวอักษร'
      ],
      field5_10Rules: [
        v => !!v || 'กรุณากรอกข้อมูล',
        v => (v && v.length >= 5) || 'กรุณากรอกข้อมูลอย่างน้อย 5 ตัวอักษร',
        v => (v && v.length <= 10) || 'ข้อมูลต้องไม่เกิน 10 ตัวอักษร'
      ],
      field300Rules: [
        v => !!v || 'กรุณากรอกข้อมูล',
        v => (v && v.length <= 300) || 'ข้อมูลต้องไม่เกิน 300 ตัวอักษร'
      ],

      lists: [
        { value: 'pid', text: 'เลขประจำตัวประชาชน' },
        { value: 'birthdate', text: 'วันเดือนปีเกิด' },
        { value: 'address', text: 'ที่อยู่ตามหน้าบัตร' },
        { value: 'title', text: 'คำนำหน้านามภาษาไทย' },
        { value: 'given_name', text: 'ชื่อภาษาไทย' },
        { value: 'middle_name', text: 'ชื่อกลางภาษาไทย' },
        { value: 'family_name', text: 'ชื่อสกุลภาษาไทย' },
        { value: 'title_en', text: 'คำนำหน้านามภาษาอังกฤษ' },
        { value: 'given_name_en', text: 'ชื่อภาษาอังกฤษ' },
        { value: 'middle_name_en', text: 'ชื่อกลางภาษาอังกฤษ' },
        { value: 'family_name_en', text: 'ชื่อสกุลภาษาอังกฤษ' },
        { value: 'name', text: 'ชื่อเต็มภาษาไทย' },
        { value: 'name_en', text: 'ชื่อเต็มภาษาอังกฤษ' },
        { value: 'gender', text: 'เพศ' },
        { value: 'date_of_issuance', text: 'วันที่ออกบัตร' },
        { value: 'date_of_expiry', text: 'วันที่หมดอายุ' },
        { value: 'smartcard_code', text: 'เลขใต้รูป' },
        { value: 'ial', text: 'ระดับความน่าเชื่อถือ (IAL Level)' }
      ],

      colors: ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange'],
      index: -1,
      items: [{ header: 'พิมพ์ IP Address แล้วกด Enter เพื่อเพิ่ม' }],
      nonce: 1,
      model: [],
      search: null,

      unfocusAppEnName: false,
      unfocusAppAbbreviate: false,
      appEnNameIsUnique: false,
      appAbbreviateIsUnique: false,
      appEnNameBeforeEdit: null,
      appAbbreviateBeforeEdit: null,

      formRole: '1',
      isValidForm: false,

      appName: null,
      appEnName: null,
      appAbbreviate: null,
      purpose: null,

      callbackUrl: null,
      ipAddressChips: [],
      logoImg: null,
      isValidLogoImg: false,
      policyUrl: null,
      termsOfUseUrl: null,
      webHook: '',
      listHeaders: 1,
      headersKeyCollections: [],
      headersValCollections: [],

      neededInfoScopes: [],
      neededInfoScopesGet: [],
      apiSpecURL: null,

      addPid: null,

      asList: [],
      asSelected: [],
      headerAS: [
        {
          text: 'ชื่อแอปพลิเคชัน AS',
          align: 'start',
          sortable: false,
          value: 'name',
          class: 'rounded-tl-lg'
        },
        {
          text: 'เอกสารการใช้งาน',
          align: 'start',
          sortable: false,
          value: 'file',
          class: 'rounded-tr-lg'
        }
      ]
    }
  },
  mounted() {
    this.getAppByID(this.$route.params.id)

    if (!this.userInfo) {
      console.log('Store is empty!')
      this.$router.push('/')
    }
  },
  computed: {
    userInfo: get('userStore/userInfo'),
    userOrganization: get('userStore/userOrganization')
  },
  watch: {
    appEnName(val, prev) {
      this.appEnName = val.toLowerCase()

      if (val == this.appEnNameBeforeEdit) {
        this.appEnNameIsUnique = true
        return true
      }

      if (val != prev) {
        this.appEnNameIsUnique = false
      }
    },
    appAbbreviate(val, prev) {
      if (val == this.appAbbreviateBeforeEdit) {
        this.appAbbreviateIsUnique = true
        return true
      }

      if (val != prev) {
        this.appAbbreviateIsUnique = false
      }
    },
    model(val, prev) {
      if (val.length === prev.length) {
        return false
      }

      this.model = val.map(v => {
        if (typeof v === 'string') {
          v = {
            text: v
          }
          this.ipAddressChips.push(v.text)
          this.nonce++
        }
        return v
      })
    }
  },
  methods: {
    errorHandler: errorHandler,
    async getAppByID(id) {
      let type = localStorage.getItem('type')
      type = type === 'main' ? 'idp' : 'sandbox'
      let res = await Applications.getApplicationByID(id, type)
      if (!res) {
        this.$router.push('/')
      } else {
        let {
          applicationName,
          applicationEngName,
          organizeAbbreviate,
          purpose,
          callbackUrl,
          applicationScopeID,
          logoImage,
          policy,
          termOfUse,
          ipAddress,
          addPID,
          webhookUrl,
          webhookHeader
        } = res.data[0]

        if (webhookHeader !== undefined) {
          let obj = this
          webhookHeader.map(({ key, value }, idx) => {
            obj.headersKeyCollections[idx] = key
            obj.headersValCollections[idx] = value
          })
          obj.listHeaders =
            webhookHeader.length === 0 ? 1 : webhookHeader.length
        }

        let scope = applicationScopeID.split('#')
        let ips = ipAddress.split('#')

        this.neededInfoScopes = scope
        this.neededInfoScopesGet = scope
        this.appName = applicationName
        this.appEnName = applicationEngName
        this.appAbbreviate = organizeAbbreviate
        this.purpose = purpose
        this.logoImg = logoImage
        this.termsOfUseUrl = termOfUse.replace('https://', '')
        this.policyUrl = policy.replace('https://', '')
        this.callbackUrl = callbackUrl
        this.model = ips
        this.addPid = addPID
        if (webhookUrl == ' ') {
          this.webHook = ''
        } else {
          this.webHook = webhookUrl.replace('https://', '')
        }

        this.appEnNameBeforeEdit = applicationEngName
        this.appAbbreviateBeforeEdit = organizeAbbreviate
      }
    },
    back() {
      this.$router.push(`/application/${this.$route.params.id}`)
    },
    dialogScopes(scope) {
      if (this.neededInfoScopesGet.includes(scope)) {
        Swal.fire({
          title: 'ไม่อนุญาตให้แก้ไข',
          icon: 'warning',
          text: 'ข้อมูลที่ต้องการใช้ไม่สามารถเปลี่ยนแปลงได้'
        })
      }
    },
    onImgLoadError() {
      this.isValidLogoImg = false
    },
    onImgLoadSuccess() {
      this.isValidLogoImg = true
    },
    rmvHeader(idx) {
      this.listHeaders--

      if (this.headersKeyCollections.length > 0) {
        this.headersKeyCollections.splice(idx, 1)
      }
      if (this.headersValCollections.length > 0) {
        this.headersValCollections.splice(idx, 1)
      }
    },
    headerListValue() {
      this.listHeaders++
    },
    checkIP(ip) {
      let isIP = ip
        .split('.')
        .map(e => /^(25[0-5]|2[0-4]\d|[01]?\d\d?)$/.test(e))
      return isIP.every(e => e === true)
    },
    checkURLImg(url) {
      return url.match(/\.(jpeg|jpg|gif|png|JPEG|JPG|GIF|PNG)$/) != null
    },
    checkAppNameUnique() {
      if (this.appEnName && !this.appEnNameIsUnique) {
        this.unfocusAppEnName = true
        setTimeout(() => {
          this.appEnNameIsUnique = true
          this.unfocusAppEnName = false
        }, 3000)
      }

      if (
        this.appAbbreviate &&
        this.appAbbreviate.length >= 5 &&
        !this.appAbbreviateIsUnique
      ) {
        this.unfocusAppAbbreviate = true
        setTimeout(() => {
          this.appAbbreviateIsUnique = true
          this.unfocusAppAbbreviate = false
        }, 2000)
      }
    },
    remove(index) {
      this.ipAddressChips = this.ipAddressChips.filter((r, idx) => {
        if (idx != index) {
          return r
        } else {
          return 0
        }
      })
    },
    async submit() {
      if (!this.$refs.entryForm.validate()) {
        Swal.fire({
          title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
          icon: 'warning'
        })
        return false
      }

      if (!this.appEnNameIsUnique) {
        Swal.fire({
          title: 'มีชื่อแอปพลิเคชันภาษาอังกฤษนี้ในระบบแล้ว',
          text: 'กรุณากรอกตั้งชื่อแอปพลิเคชันภาษาอังกฤษใหม่',
          icon: 'error'
        })
        return false
      }

      if (!this.appAbbreviateIsUnique) {
        Swal.fire({
          title: 'มีชื่อย่อแอปพลิเคชันนี้ในระบบแล้ว',
          text: 'กรุณากรอกตั้งชื่อย่อแอปพลิเคชัน',
          icon: 'error'
        })
        return false
      }

      if (this.ipAddressChips.length == 0) {
        Swal.fire({
          title: 'กรุณากรอกหมายเลข IP Address',
          icon: 'error'
        })
        return false
      }

      if (
        (this.formRole == '1' || this.formRole == '3') &&
        this.neededInfoScopes.length == 0
      ) {
        Swal.fire({
          title: 'กรุณากรอกข้อมูลที่ต้องการใช้',
          icon: 'error'
        })
        return false
      }

      if (this.formRole == '2' && !this.isUrlValid(this.apiSpecURL)) {
        Swal.fire({
          title: 'กรุณากรอก Document URL (API Specification) ให้ถูกต้อง',
          text: 'ตัวอย่าง https://example.com/avatar.pdf',
          icon: 'warning'
        })
        return false
      }

      if (!this.checkURLImg(this.logoImg)) {
        Swal.fire({
          title: 'กรุณากรอก URL Image ให้ถูกต้อง',
          text:
            'รองรับไฟล์รูปภาพ jpeg, jpg, gif, png ตัวอย่าง https://example.com/avatar.jpg',
          icon: 'warning'
        })
        return false
      }

      let headerTrueCollections = []
      this.headersKeyCollections.map((_, index) => {
        headerTrueCollections.push({
          headerKey: this.headersKeyCollections[index],
          headerValue: this.headersValCollections[index]
        })
      })

      if (this.termsOfUseUrl.substring(0, 8) != 'https://') {
        this.termsOfUseUrl = 'https://' + this.termsOfUseUrl
      }
      if (this.policyUrl.substring(0, 8) != 'https://') {
        this.policyUrl = 'https://' + this.policyUrl
      }
      if (this.webHook && this.webHook.substring(0, 8) != 'https://') {
        this.webHook = 'https://' + this.webHook
      }

      let validIP = []
      this.ipAddressChips.map(item => {
        if (!this.checkIP(item)) {
          Swal.fire({
            title: 'กรุณากรอกหมายเลข IP ให้ถูกต้อง',
            icon: 'error'
          })
          return false
        } else {
          validIP.push(item)
        }
      })

      let data = {
        orgID: this.userOrganization.organizeID,
        purpose: this.purpose,
        appAbbreviate: this.appAbbreviate,
        appName: this.appName,
        appEnName: this.appEnName,
        logoImg: this.logoImg,
        pid: parseInt(this.userInfo.pid),
        role: parseInt(this.formRole),
        termsOfUseUrl: this.termsOfUseUrl,
        policyUrl: this.policyUrl,
        // webHookUrl: this.webHook === "" ? " " : this.webHook,
        // webhookHeaders: headerTrueCollections,
        ipAddress: validIP,
        callbackUrl: this.callbackUrl,
        scopes: this.neededInfoScopes,
        ASApiSpecUrl: this.apiSpecURL,
        asUsages: this.asSelected
      }

      let body = {}
      if (this.formRole == '1') {
        body = data
        delete body.ASApiSpecUrl
      } else if (this.formRole == '2') {
        body = data
        delete body.callbackUrl
        delete body.scopes
      } else if (this.formRole == '3') {
        body = data
      }

      try {
        let id = this.$route.params.id
        let type = localStorage.getItem('type')
        type = type === 'main' ? 'idp' : 'sandbox'
        await Applications.updateApplication(id, body, type).then(() => {
          Swal.fire({
            title: 'บันทึกสำเร็จ',
            icon: 'success'
          }).then(async () => {
            let data = {
              requestPid: Number(this.userInfo.pid),
              orgID: this.userOrganization.organizeID,
              ipAddress: ip.address(),
              detail: 'แก้ไข application ' + this.appName
            }
            try {
              await General.postActivityLogs(data)
            } catch (err) {
              console.error(err)
            }

            this.$router.push(`/application/${id}`)
          })
        })
      } catch (e) {
        Swal.fire({
          title: 'บันทึกไม่สำเร็จ',
          icon: 'error',
          text: this.errorHandler(e.response)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-btn:not(.v-btn--round).v-size--default.btn-webhook {
  height: 49px;
  min-width: 48px !important;
}

.radio-company-type {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 2.5rem;

  p {
    font-size: 1rem;
    height: 1.5rem;
    padding: 0;
    margin-bottom: 5px;
    margin-right: 2px;
  }
}

.width-100 {
  width: 100%;
}

.container-page {
  position: relative;
  min-height: 100vh;
  width: 100vw;
  z-index: 00;
}

.bg-app {
  background: url(~@/assets/add-app-recolor.png) right;
  background-size: auto;
  background-repeat: no-repeat;
  background-origin: content-box;
}

@media only screen and (max-width: 600px) {
  .bg-app {
    background: none;
  }
}

.v-data-table::v-deep th {
  font-size: 1rem !important;
  font-weight: 400 !important;
  color: #f6f6fa !important;
  background: #3a3d80;
}

.v-data-table::v-deep tbody tr:nth-of-type(even) {
  background-color: #ecf0fe;
}

.v-data-table::v-deep .rounded-tl-lg {
  border-top-left-radius: 4px !important;
}

.v-data-table::v-deep .rounded-tr-lg {
  border-top-right-radius: 4px !important;
}

.rounded-b-lg {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
</style>
