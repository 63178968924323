<template>
  <div class="logged-wrapper bg-select-app">
    <v-row>
      <v-col cols="12" class="mb-10" justify="center">
        <v-card class="centered" tile v-if="userInfo !== null">
          <v-list dense style="padding: 0px;">
            <v-subheader style="background-color: #3a3d80;height: 50px;text-align: center;display: grid;">
              <h2 style="color: #FFFFFF;">เลือกหน่วยงานที่จะดำเนินการ</h2>
            </v-subheader>
            <v-list-item-group color="primary">
              <v-list-item v-for="(item, i) in orgList" :key="i">
                <v-list-item-content @click="selectOrg(item.orgId)">
                  <v-list-item-title style="height: 50px;text-align: center;display: grid;">
                    <h3 style="color: #0048a3;">
                      {{ item.orgId + ' - ' + item.title }}
                    </h3>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
import ip from 'ip'
import { sync } from 'vuex-pathify'
import { api, General } from '@/core/api'

export default {
  name: 'SelectOrganization',
  data() {
    return {
      orgList: [],
    }
  },
  computed: {
    userOrganization: sync('userStore/userOrganization'),
    userInfo: sync('userStore/userInfo'),
  },
  async mounted() {
    localStorage.setItem('type', 'main')
    this.typeEnvi = 'main'
    try {
      let dataLogin = await axios.get('/idp/api/login/v1/info/')
      if (dataLogin.data.userInfo.list.length === 1) {
        this.userInfo = dataLogin.data.userInfo
        this.userOrganization = dataLogin.data.userOrganization
        api.defaults.headers.common['Authorization'] = `bearer ${dataLogin.data.token}`
        this.saveLog()
        this.$router.push({ name: 'SelectEnvironment' })
      } else {
        this.userInfo = dataLogin.data.userInfo
        this.orgList = []
        for (let index = 0; index < dataLogin.data.userInfo.list.length; index++) {
          this.orgList.push({
            title: dataLogin.data.userInfo.list[index].organizeName,
            subtitle: `${dataLogin.data.userInfo.list[index].department == null ? '' : dataLogin.data.userInfo.list[index].department} 
            ${dataLogin.data.userInfo.list[index].ministry == null ? '' : dataLogin.data.userInfo.list[index].department}`,
            orgId: dataLogin.data.userInfo.list[index].organizeID
          })
        }
      }
    } catch (err) {
      this.$router.push({ name: 'Login' })
    }
  },
  methods: {
    async saveLog() {
      let data = {
        requestPid: Number(this.userInfo.pid),
        orgID: this.userOrganization === null ? 0 : this.userOrganization.organizeID,
        ipAddress: ip.address(),
        detail: 'เข้าสู่ระบบ'
      }

      if (!localStorage.getItem('logsLogin')) {
        try {
          await General.postActivityLogs(data)
          localStorage.setItem('logsLogin', 'saved')
        } catch (err) {
          console.error(err)
        }
      }
    },
    async selectOrg(orgId) {
      try {
        let updLogin = await axios.put('/idp/api/login/v1/info/', {
          organizationID: orgId
        })
        if (updLogin.status === 200) {
          this.userInfo = updLogin.data.userInfo
          this.userOrganization = updLogin.data.userOrganization
          api.defaults.headers.common['Authorization'] = `bearer ${updLogin.data.token}`
          this.saveLog()
          this.$router.push({ name: 'SelectEnvironment' })
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.logged-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 1.5rem;
}

.bg-select-app {
  background: url(~@/assets/login-bg.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: right;
  background-size: 85%;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  width: 50%;
  max-height: 60%;
  overflow-y: auto;
}
</style>
