const errorHandler = response => {
  let status = response.status ? response.status : ''
  let errorObj = {
    status: status,
    error: '',
    info: '',
    message: ''
  }

  let res = response.data ? response.data : null
  if (!res) {
    return 'เกิดข้อผิดพลาดบางประการ'
  }

  if (typeof res === 'object' && Object.keys(res).length) {
    for (let i in res) {
      if (typeof res[i] === 'object' && Object.keys(res[i]).length) {
        for (let j in res[i]) {
          if (j == 'info') {
            errorObj.info = res[i][j]
          } else if (j == 'message') {
            errorObj.message = res[i][j]
          } else if (j == 'error') {
            errorObj.error = res[i][j]
          }
        }
      } else {
        if (i == 'info' && errorObj.info == '') {
          errorObj.info = res[i]
        } else if (i == 'message' && errorObj.message == '') {
          errorObj.message = res[i]
        } else if (i == 'error' && errorObj.error == '') {
          errorObj.error = res[i]
        }
      }
    }
  }

  return `${errorObj.status} ${errorObj.error} ${errorObj.info} ${errorObj.message}`
}

export { errorHandler }
