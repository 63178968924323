import { api } from './index'

const prefix = '/idp/api/v1/actlogs'

export default {
  postActivityLogs(data) {
    console.log(`##### postActivityLogs #####`)
    return api.post(`${prefix}/`, data)
  }
}
