import Applications from './applications'
import Officers from './officers'
import Catm from './ccaattmm'
import Consent from './consent'
import General from './general'
import axios from 'axios'
import Nprogress from 'nprogress'

const api = axios.create({
  timeout: 10000
})

api.interceptors.request.use(config => {
  Nprogress.start()
  return config
})

api.interceptors.response.use(
  res => {
    Nprogress.done()
    return res
  },
  error => {
    Nprogress.done()
    if (error.response) {
      if (401 === error.response.status) {
        window.localStorage.clear()
        window.location = '/'
      } else {
        return Promise.reject(error)
      }
    }
  }
)

export { Applications, Officers, Catm, Consent, General, api }
