import Vue from 'vue'
import Vuex from 'vuex'
import userStore from '@/store/modules/userStore'
import pathify from 'vuex-pathify'
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [pathify.plugin],
  modules: {
    userStore
  },
  mutations: {},
  actions: {},
  getters: {}
})
