import Vue from "vue";
import VueRouter from "vue-router";
// PAGE
import HomePage from "../views/HomePage.vue";
import Login from "../views/Login.vue";
import SelectOrganization from "../views/SelectOrganization.vue";
import SelectEnvironment from "../views/SelectEnvironment.vue";
import RegisterOfficer from "../views/RegisterOfficer.vue";
import ApplicationManage from "../views/application/ApplicationManage.vue";
import ApplicationInfo from "../views/application/ApplicationInfo.vue";
import ApplicationEdit from "../views/application/ApplicationEdit.vue";
import RegisterApplication from "../views/application/RegisterApplication.vue";
import ManagePrivilege from "../views/application/ManagePrivilege.vue";
import RegisterOfficerApp from "../views/application/RegisterOfficerApp.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: HomePage,
    beforeEnter: (to, from, next) => {
      if (window.location.origin.includes("idp.bora.dopa.go.th")) {
        window.location.href =   "https://digitalid.bora.dopa.go.th/idp/"
      } else {
        next()
      }
    },
    children: [
      {
        path: '/',
        redirect: '/org'
      },
      {
        path: "/login",
        name: "Login",
        component: Login,
        meta: { title: 'ระบบจัดการแอปพลิเคชัน' }
      },
      {
        path: "/org",
        name: "SelectOrganization",
        component: SelectOrganization,
        meta: { title: 'ระบบจัดการแอปพลิเคชัน' }
      },
      {
        path: "/env",
        name: "SelectEnvironment",
        component: SelectEnvironment,
        meta: { title: 'ระบบจัดการแอปพลิเคชัน' }
      },
      {
        path: '/register/officer',
        name: 'RegisterOfficer',
        component: RegisterOfficer,
        meta: { title: 'ลงทะเบียนเจ้าหน้าที่' }
      },
      {
        path: '/home',
        name: 'ApplicationManage',
        component: ApplicationManage,
        meta: { title: 'ระบบจัดการแอปพลิเคชัน' }
      },
      {
        path: '/application/:id',
        name: 'ApplicationInfo',
        component: ApplicationInfo,
        meta: { title: 'ระบบจัดการแอปพลิเคชัน (รายละเอียด)' }
      },
      {
        path: '/application/:id/edit',
        name: 'ApplicationEdit',
        component: ApplicationEdit,
        meta: { title: 'ระบบจัดการแอปพลิเคชัน (แก้ไข)' }
      },
      {
        path: '/application',
        name: 'RegisterApplication',
        component: RegisterApplication,
        meta: { title: 'ระบบลงทะเบียนแอปพลิเคชัน' }
      },
      {
        path: '/privilege',
        name: 'ManagePrivilege',
        component: ManagePrivilege,
        meta: { title: 'ระบบจัดการสิทธิแอปพลิเคชัน' }
      },
      {
        path: '/officer',
        name: 'RegisterOfficerApp',
        component: RegisterOfficerApp,
        meta: { title: 'ลงทะเบียนเพิ่มเจ้าหน้าที่' }
      },
    ]
  }
]
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
