import { api } from './index'

const prefix = '/idp/api/v1/ccaattmm'

export default {
  getCC() {
    return api.get(`${prefix}/cc/`)
  },
  getAA(cc) {
    return api.get(`${prefix}/aa/${cc}`)
  }
}
