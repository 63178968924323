<template>
  <div class="select-wrapper bg-officer">
    <v-row>
      <v-col cols="12" md="7" class="mb-10">
        <v-row>
          <v-col cols="12">
            <base-page-header title="แอปพลิเคชัน" subtitle="แอปพลิเคชันที่ขอใช้ ThaID ของท่าน"></base-page-header>
          </v-col>

          <v-col cols="12">
            <v-row>
              <v-col cols="6" md="4">
                <v-card color="primary" height="200" class="card-proj" @click="$router.push('/application')"
                  v-if="typeEnvi === 'main'">
                  <div class="text-center">
                    <h3>
                      <v-icon color="#f6f6fa">mdi-plus-thick</v-icon>
                    </h3>
                    <div>
                      <h3 class="font-weight-regular mt-1">เพิ่มแอปพลิเคชัน</h3>
                    </div>
                  </div>
                </v-card>
                <v-card color="#f89929" height="200" class="card-proj" @click="$router.push('/application')"
                  v-if="typeEnvi === 'sandbox'">
                  <div class="text-center">
                    <h3>
                      <v-icon color="#f6f6fa">mdi-plus-thick</v-icon>
                    </h3>
                    <div>
                      <h3 class="font-weight-regular mt-1">เพิ่มแอปพลิเคชัน</h3>
                    </div>
                  </div>
                </v-card>
              </v-col>

              <template v-if="applications.length === 0">
                <div class="pa-5">
                  <h3>ไม่พบแอปพลิเคชัน</h3>
                </div>
              </template>
              <template v-else>
                <template v-for="(app, idx) in applications">
                  <v-col cols="6" md="4" :key="idx" @click="$router.push('/application/' + app.applicationID)">
                    <base-card-app :title="app.applicationName" :subtitle="`${userOrganization.department == null ||
                      userOrganization.department.trim() == ''
                      ? ''
                      : userOrganization.department
                      }
                                                            ${userOrganization.ministry == null || userOrganization.ministry.trim() == ''
                        ? ''
                        : userOrganization.ministry
                      } `
                      " :logo="app.logoImage || null"></base-card-app>
                  </v-col>
                </template>
              </template>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="5" v-if="typeEnvi === 'main'">
        <v-row>
          <v-col cols="12" xs="12" md="6">
            <base-page-header title="เจ้าหน้าที่" subtitle="เจ้าหน้าที่ในหน่วยงานของท่าน"></base-page-header>
          </v-col>
          <v-col cols="12" xs="12" md="6" align-self="center"
            :class="$vuetify.breakpoint.mdAndUp ? 'text-right' : 'text-left'" v-if="role === 1">
            <router-link to="/privilege" class="add-officer">
              <v-btn large tile outlined color="primaryDarken1" class="rounded-lg">
                <v-icon size="18">mdi-circle-edit-outline</v-icon>
                <strong>จัดการสิทธิ</strong>
              </v-btn>
            </router-link>
            <span style="margin-left: 10px;" />
            <router-link to="/officer" class="add-officer">
              <v-btn large tile outlined color="primaryDarken1" class="rounded-lg">
                <v-icon size="18">mdi-plus</v-icon>
                <strong>เพิ่มเจ้าหน้าที่</strong>
              </v-btn>
            </router-link>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xs="12" md="12">
            <officerInfo />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Dialog select app type -->
    <v-dialog v-model="dialogAppType" max-width="500">
      <v-card>
        <v-layout class="mb-1 pt-4">
          <v-flex class="text-center">
            <span class="select-app-type">เลือกประเภทแอปพลิเคชัน</span>
            <v-divider class="mt-4"></v-divider>
          </v-flex>
        </v-layout>
        <v-layout class="px-2 py-5">
          <v-flex class="mx-3">
            <v-card color="primary" height="200" class="card-proj" @click="$router.push('/application')">
              <div class="text-center">
                <h3>
                  <v-icon color="#f6f6fa">mdi-plus-thick</v-icon>
                </h3>
                <div>
                  <h3 class="font-weight-regular mt-1">RP/AS</h3>
                </div>
              </div>
            </v-card>
          </v-flex>
          <v-flex class="mx-3">
            <v-card color="primary" height="200" class="card-proj" @click="$router.push('/application/fvs')">
              <div class="text-center">
                <h3>
                  <v-icon color="#f6f6fa">mdi-plus-thick</v-icon>
                </h3>
                <div>
                  <h3 class="font-weight-regular mt-1">FVS</h3>
                </div>
              </div>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Applications } from '@/core/api'
import { get, sync } from 'vuex-pathify'
import Swal from 'sweetalert2'
import officerInfo from './OfficerInfo.vue'

export default {
  name: 'ApplicationManage',
  components: { officerInfo },
  data() {
    return {
      dialogAppType: false,
      applications: [],
      role: 0
    }
  },
  computed: {
    userOrganization: get('userStore/userOrganization'),
    userInfo: get('userStore/userInfo'),
    typeEnvi: sync('userStore/typeEnvi'),
  },
  created() { },
  async mounted() {
    localStorage.setItem('home', 'rp')
    await this.checkLogin()
    await this.getApp()
    const result = this.userInfo.list.find(member => {
      return member.organizeID === this.userOrganization.organizeID
    })
    this.role = result.role
    let type = localStorage.getItem('type')
    this.typeEnvi = type
    console.log("typeEnvi", this.typeEnvi)
  },
  methods: {
    async checkLogin() {
      try {
        if (this.$router.history.current.hash) {
          let urlParams = this.$router.history.current.hash.split('/')[2]
          urlParams = urlParams.replace('?', '')
          let jsonObj = JSON.parse(
            '{"' +
            decodeURI(urlParams)
              .replace(/"/g, '\\"')
              .replace(/&/g, '","')
              .replace(/=/g, '":"') +
            '"}'
          )
          Swal.fire({
            title: 'เข้าสู่ระบบล้มเหลว',
            icon: 'error',
            text: jsonObj.errorMessage
          }).then(() => {
            console.log('Login failed')
            this.$router.push({ name: 'Login' })
          })
        }
      } catch (e) {
        console.log(e.message)
      }
    },
    async getApp() {
      this.applications = []
      try {
        let type = localStorage.getItem('type')
        type = type === 'main' ? 'idp' : 'sandbox'
        let apps = await Applications.getApplications(type)
        apps.data.data.map(r => {
          if (r.organizeID === this.userOrganization.organizeID) {
            if (r.active == 1) {
              this.applications.push(r)
            }
          }
        })
      } catch (err) {
        console.error(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f6f6fa;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #616499;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3a3d80;
}

.select-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 1.5rem;
}

.bg-officer {
  background: url(~@/assets/officer-custom.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: right;
  background-size: 85%;
}

.card-proj {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #f6f6fa;

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
}

.scroll-y {
  overflow-x: scroll;
}

.font-white {
  color: #ffffff !important;
  font-family: 'Sarabun', sans-serif !important;
}

.add-officer {
  text-decoration: none;
}

.rounded-lg {
  border-radius: 4px !important;
}

.select-app-type {
  color: #636363;
  font-size: 18pt;
  font-weight: 500;
}
</style>
