<template>
  <div class="header-wrapper">
    <div class="line-blue-gradient" v-if="typeEnvi === 'main'" />
    <div class="line-blue-gradient-sandbox" v-if="typeEnvi === 'sandbox'" />
    <h2>{{ title }}</h2>
    <h5>{{ subtitle }}</h5>
  </div>
</template>

<script>
import { get } from 'vuex-pathify'

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    }
  },
  computed: {
    typeEnvi: get('userStore/typeEnvi'),
  },
}
</script>

<style lang="scss" scoped>
.header-wrapper {
  h2 {
    color: #2c2738;
    letter-spacing: 1px;
  }

  h5 {
    color: #3a3d80;
    font-size: 1rem;
  }
}

.line-blue-gradient {
  height: 0.5rem;
  border-radius: 3px;
  background: transparent linear-gradient(270deg, #009ffd 0%, #3a3d80 100%) 0% 0% no-repeat padding-box;
  animation: long 0.6s ease-in;
  animation-fill-mode: forwards;
}

.line-blue-gradient-sandbox {
  height: 0.5rem;
  border-radius: 3px;
  background: transparent linear-gradient(270deg, #f7b56b 0%, #f89929 100%) 0% 0% no-repeat padding-box;
  animation: long 0.6s ease-in;
  animation-fill-mode: forwards;
}

@media only screen and (max-width: 600px) {
  .line-blue-gradient {
    animation: longer 0.7s ease-in;
    animation-fill-mode: forwards;
  }
}

@keyframes longer {
  0% {
    width: 0rem;
  }

  60% {
    width: 100%;
  }

  100% {
    width: 20%;
  }
}

@keyframes long {
  0% {
    width: 0rem;
  }

  80% {
    width: 7rem;
  }

  100% {
    width: 5rem;
  }
}

@keyframes enter {
  0% {
    transform: scale(0);
  }

  90% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}
</style>
