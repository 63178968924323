<template>
  <div class="info-app-wrapper bg-graph">
    <v-row>
      <v-col cols="12" md="6" sm="6">
        <!-- Page title -->
        <v-row>
          <v-col cols="12" md="12">
            <base-page-header v-if="userOrganization" :title="appData.applicationName" :subtitle="`${userOrganization.department == null ||
              userOrganization.department.trim() == ''
              ? ''
              : userOrganization.department
              } 
                                                                                                                                                                                                                                                                                                                                                                                                                                                ${userOrganization.ministry ==
                null ||
                userOrganization.ministry.trim() ==
                ''
                ? ''
                : userOrganization.ministry
              }`
              "></base-page-header>
          </v-col>
        </v-row>

        <!-- Card status group -->
        <v-row class="row-wrap cursor-refresh mt-5" @click="getDataConsent($route.params.id)">
          <v-col cols="12" md="12">
            <base-card-stat color="approved" topic="ยืนยันการใช้งาน" :value="approved || 0"></base-card-stat>
          </v-col>
          <v-col cols="12" md="4">
            <base-card-stat color="pending" topic="รอยืนยันการใช้งาน" :value="pending || 0"></base-card-stat>
          </v-col>
          <v-col cols="12" md="4">
            <base-card-stat color="disapproved" topic="ไม่ยืนยันการใช้งาน" :value="disapproved || 0"></base-card-stat>
          </v-col>
          <v-col cols="12" md="4">
            <base-card-stat color="timeout" topic="หมดเวลาใช้งาน" :value="timeout || 0"></base-card-stat>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <!-- Manage app -->
        <v-row class="row-wrap mt-5 mb-8">
          <v-col cols="12" md="12">
            <base-page-header title="จัดการแอปพลิเคชัน"></base-page-header>
          </v-col>

          <v-col cols="12" md="4">
            <v-card v-if="isOwner || role === 1" color="edit" height="170px" class="card-proj mt-4"
              @click="changePage(appID)">
              <div class="text-center">
                <h3>
                  <v-icon color="white" size="40">mdi-pen</v-icon>
                </h3>
                <div class="manage-app-btn mt-1">แก้ไขแอปพลิเคชัน</div>
              </div>
            </v-card>
            <v-card v-else color="secondaryLighten1" height="170px" class="card-proj-disable mt-4">
              <div class="text-center">
                <h3>
                  <v-icon color="secondary" size="40">mdi-pen</v-icon>
                </h3>
                <div class="manage-app-btn secondary--text mt-1">
                  แก้ไขแอปพลิเคชัน
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card v-if="isOwner || role === 1" color="delete" height="170px" class="card-proj mt-4"
              @click="confirmDeletePage(appID)">
              <div class="text-center">
                <h3>
                  <v-icon color="white" size="40">mdi-delete-variant</v-icon>
                </h3>
                <div class="manage-app-btn mt-1">ลบแอปพลิเคชัน</div>
              </div>
            </v-card>
            <v-card v-else color="secondaryLighten1" height="170px" class="card-proj-disable mt-4">
              <div class="text-center">
                <h3>
                  <v-icon color="secondary" size="40">mdi-delete-variant</v-icon>
                </h3>
                <div class="manage-app-btn secondary--text mt-1">
                  ลบแอปพลิเคชัน
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" md="4"></v-col>
          <v-col cols="12" md="4">
            <v-card v-if="(isOwner || role === 1) && typeEnvi === 'main'" color="primaryDarken1" height="170px"
              class="card-proj mt-4" @click="dialogConsent()">
              <div class="text-center">
                <h3>
                  <v-icon color="white" size="42">mdi-key-plus</v-icon>
                </h3>
                <div class="manage-app-btn mt-1">จัดการแอปพลิเคชันคีย์</div>
              </div>
            </v-card>
            <v-card v-else color="secondaryLighten1" height="170px" class="card-proj-disable mt-4">
              <div class="text-center">
                <h3>
                  <v-icon color="secondary" size="42">mdi-key-plus</v-icon>
                </h3>
                <div class="manage-app-btn secondary--text mt-1">
                  จัดการแอปพลิเคชันคีย์
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>

        <!-- AS details -->
        <v-row wrap class="row-wrap-text border elevation-1 mt-8 mb-4 mx-0" style="display: flex; position: relative"
          v-if="appData && appData.role == 9">
          <v-col cols="12" md="12" sm="12" class="mb-3">
            <h2>ข้อมูลการให้บริการข้อมูล AS</h2>
          </v-col>
          <v-col cols="6" md="6" sm="6">
            <h4>เอกสารการใช้งาน</h4>
          </v-col>
          <v-col cols="6" md="6" sm="6">
            <span>{{ appData.organizeAbbreviate }}</span>
          </v-col>
          <v-col cols="6" md="6" sm="6">
            <h4>(API Specification)</h4>
          </v-col>
          <v-col cols="6" md="6" sm="6">
            <span>ไม่มีข้อมูล</span>
          </v-col>
        </v-row>

        <!-- Request list -->
        <v-row class="row-wrap mt-4 mb-8" v-if="appData && appData.role == 9">
          <v-col cols="12" md="12">
            <h2>รายการการขอใช้ข้อมูลจากหน่วยงาน AS</h2>
          </v-col>
          <v-col cols="12" md="12">
            <v-data-table hide-default-footer :headers="headerAS" :items="asList" item-key="name"
              class="elevation-1 rounded-t-xl rounded-b-lg" :footer-props="{
                'items-per-page-text': 'แสดง',
                'items-per-page-options': [5, 10, 20, -1],
                'items-per-page-all-text': 'ทั้งหมด'
              }">
              <template v-slot:no-data>
                <v-layout>
                  <v-flex xs12 class="secondary--text">ไม่มีข้อมูล</v-flex>
                </v-layout>
              </template>
              <template #footer.page-text="props">
                {{ props.pageStart }} - {{ props.pageStop }} จากทั้งหมด
                {{ props.itemsLength }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>

      <!-- Website details -->
      <v-col cols="12" md="6" sm="6">
        <v-row class="mb-3">
          <v-col cols="12">
            <base-page-header title="ข้อมูลเว็บไซต์"></base-page-header>
          </v-col>
        </v-row>
        <v-row wrap class="row-wrap-text border elevation-1 mt-2" style="display: flex; position: relative">
          <v-col cols="12" class="mb-2">
            <v-layout class="mt-4">
              <h3>บริษัท / หน่วยงาน / Application</h3>
              <v-divider class="ml-2 mt-4"></v-divider>
            </v-layout>
          </v-col>

          <v-col cols="6" md="6" sm="6">
            <h4>ชื่อ</h4>
          </v-col>
          <v-col cols="6" md="6" sm="6">
            <span>{{ appData.applicationEngName }}</span>
          </v-col>

          <v-col cols="6" md="6" sm="6">
            <h4>ชื่อย่อ</h4>
          </v-col>
          <v-col cols="6" md="6" sm="6">
            <span>{{ appData.organizeAbbreviate }}</span>
          </v-col>

          <v-col cols="6" md="6" sm="6">
            <h4>Application ที่ใช้งาน</h4>
          </v-col>
          <v-col cols="6" md="6" sm="6">
            <span>{{ appData.applicationName }}</span>
          </v-col>

          <v-col cols="6" md="6" sm="6">
            <h4>วัตถุประสงค์</h4>
          </v-col>
          <v-col cols="6" md="6" sm="6">
            <span>{{ appData.purpose }}</span>
          </v-col>

          <v-col cols="6" md="6" sm="6">
            <h4>IP Address</h4>
          </v-col>
          <v-col cols="6" md="6" sm="6">
            <span>{{ formatAddress(appData.ipAddress) }}</span>
          </v-col>

          <v-col cols="6" md="6" sm="6">
            <h4>ชื่อผู้มีอำนาจลงนาม</h4>
          </v-col>
          <v-col cols="6" md="6" sm="6">
            {{ userOrganization.authorizedFullname || '' }}
          </v-col>

          <v-col cols="6" md="6" sm="6">
            <h4>ตำแหน่ง</h4>
          </v-col>
          <v-col cols="6" md="6" sm="6">
            {{ userOrganization.position || '' }}
          </v-col>

          <v-col cols="12" class="mt-3 mb-2">
            <v-layout>
              <h3>ที่อยู่</h3>
              <v-divider class="ml-2 mt-4"></v-divider>
            </v-layout>
          </v-col>

          <v-col cols="6">
            <h4>ชื่อสถานที่</h4>
          </v-col>
          <v-col cols="6">
            {{ userOrganization.addressName || '' }}
          </v-col>

          <v-col cols="12">
            <v-row class="pb-3">
              <v-col cols="6" md="6" lg="3">
                <h4>เลขที่ ถนน ตรอก ซอย</h4>
              </v-col>
              <v-col cols="6" md="6" lg="3">
                {{ userOrganization.addressDesc || '' }}
              </v-col>

              <v-col cols="6" md="6" lg="2">
                <h4>ตำบล/แขวง</h4>
              </v-col>
              <v-col cols="6" md="6" lg="4">
                {{ userOrganization.ttDesc || '' }}
              </v-col>

              <v-col cols="6" md="6" lg="3">
                <h4>อำเภอ/เขต</h4>
              </v-col>
              <v-col cols="6" md="6" lg="3">
                {{ userOrganization.aaDesc || '' }}
              </v-col>

              <v-col cols="6" md="6" lg="2">
                <h4>จังหวัด</h4>
              </v-col>
              <v-col cols="6" md="6" lg="4">
                {{ userOrganization.ccDesc || '' }}
              </v-col>

              <v-col cols="6" md="6" lg="3">
                <h4>เบอร์โทร</h4>
              </v-col>
              <v-col cols="6" md="6" lg="3">
                {{ userOrganization.telephone || '' }}
              </v-col>

              <v-col cols="6" md="6" lg="2">
                <h4>อีเมล</h4>
              </v-col>
              <v-col cols="6" md="6" lg="4">
                {{ userOrganization.email || '' }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="row-wrap-text border elevation-1 mt-2">
          <v-col cols="6">
            <h4>หนังสือเลขที่</h4>
          </v-col>
          <v-col cols="6">
            <h4>{{ userOrganization.documentNo || '' }}</h4>
          </v-col>

          <v-col cols="6">
            <h4>ข้อมูลที่ต้องการใช้</h4>
          </v-col>
          <v-col cols="6">
            <template v-for="(data, idx) in appData.scope">
              <v-col cols="12" class="scope" :key="idx">
                • {{ setScopes(data) }}
              </v-col>
            </template>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Dialog generate app key -->
    <v-dialog v-model="dialog" persistent max-width="650">
      <v-card>
        <v-card-title class="primary justify-center white--text">
          KEY
        </v-card-title>

        <v-card-text>
          <v-container class="mt-4 px-0" fluid>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field hide-details outlined dense readonly v-bind="attrs" v-on="on" type="text" id="endpoint"
                  label="Callback Endpoint" :value="endPoint" @click.stop.prevent="copyContent('endpoint')" />
              </template>
              <span>คลิกเพื่อคัดลอก</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field hide-details outlined dense readonly v-bind="attrs" v-on="on" type="text" label="API Key"
                  id="secret-key" class="mt-5" :value="secretKey" @click.stop.prevent="copyContent('secret-key')" />
              </template>
              <span>คลิกเพื่อคัดลอก</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field hide-details outlined dense readonly v-bind="attrs" v-on="on" id="client-id" type="text"
                  label="Client ID" class="mt-5" :value="clientId" @click.stop.prevent="copyContent('client-id')" />
              </template>
              <span>คลิกเพื่อคัดลอก</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field hide-details outlined dense readonly v-bind="attrs" v-on="on" type="text" id="client-key"
                  label="Client Secret" class="mt-5" :value="clientKey" @click.stop.prevent="copyContent('client-key')" />
              </template>
              <span>คลิกเพื่อคัดลอก</span>
            </v-tooltip>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="px-6">
          <v-spacer></v-spacer>
          <v-btn outlined color="primaryDarken1" class="primaryDarken1--text" @click="dialog = false">
            ปิด
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar">
      คัดลอกสำเร็จ
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          ปิด
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import ip from 'ip'
import Swal from 'sweetalert2'
import { Applications, General } from '../../core/api/index'
import { errorHandler } from '../../core/js/handleError'
import { get } from 'vuex-pathify'

export default {
  name: 'ApplicationInfo',
  data() {
    return {
      role: 0,
      appData: [],
      isOwner: true,
      dataConsentLoaded: false,
      dialog: false,
      endPoint: null,
      secretKey: null,
      clientId: null,
      clientKey: null,
      token: null,
      snackbar: false,
      disapproved: 0,
      pending: 0,
      approved: 0,
      timeout: 0,
      appID: null,
      asList: [],
      headerAS: [
        {
          text: 'รายการที่',
          align: 'start',
          sortable: false,
          value: 'n',
          class: 'rounded-tl-lg'
        },
        {
          text: 'ชื่อแอปพลิเคชัน AS',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        {
          text: 'เอกสารการใช้งาน',
          align: 'start',
          sortable: false,
          value: 'file',
          class: 'rounded-tr-lg'
        }
      ]
    }
  },
  computed: {
    userInfo: get('userStore/userInfo'),
    userOrganization: get('userStore/userOrganization'),
    typeEnvi: get('userStore/typeEnvi'),
  },
  async mounted() {
    const result = this.userInfo.list.find(member => {
      return member.organizeID === this.userOrganization.organizeID
    })
    this.role = result.role
    this.appID = this.$route.params.id
    await this.getAppByID(this.appID)

    if (this.userInfo) {
      if (parseInt(this.userInfo.pid) != parseInt(this.appData.addPID)) {
        this.isOwner = false
      }
    } else {
      console.log('Store is empty!')
      this.$router.push({ name: 'ApplicationManage' })
    }
  },
  methods: {
    errorHandler: errorHandler,
    setScopes(data) {
      switch (data) {
        case 'pid':
          return 'เลขประจำตัวประชาชน'
        case 'openid':
          return 'Open ID'
        case 'birthdate':
          return 'วันเดือนปีเกิด'
        case 'given_name':
          return 'ชื่อภาษาไทย'
        case 'middle_name':
          return 'ชื่อกลางภาษาไทย'
        case 'family_name':
          return 'ชื่อสกุลภาษาไทย'
        case 'given_name_en':
          return 'ชื่อภาษาอังกฤษ'
        case 'middle_name_en':
          return 'ชื่อกลางภาษาอังกฤษ'
        case 'family_name_en':
          return 'ชื่อสกุลภาษาอังกฤษ'
        case 'name':
          return 'ชื่อเต็มภาษาไทย'
        case 'name_en':
          return 'ชื่อเต็มภาษาอังกฤษ'
        case 'address':
          return 'ที่อยู่ตามหน้าบัตร'
        case 'gender':
          return 'เพศ'
        case 'title':
          return 'คำนำหน้านามภาษาไทย'
        case 'title_en':
          return 'คำนำหน้านามภาษาอังกฤษ'
        case 'date_of_issuance':
          return 'วันที่ออกบัตร'
        case 'date_of_expiry':
          return 'วันที่หมดอายุ'
        case 'smartcard_code':
          return 'เลขใต้รูป'
        case 'ial':
          return 'ระดับความน่าเชื่อถือ (IAL Level)'
      }
    },
    changePage(id) {
      this.$router.push(`/application/${id}/edit`)
    },
    confirmDeletePage(id) {
      Swal.fire({
        title: 'ลบแอปพลิเคชันนี้?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก'
      }).then(async result => {
        if (result.isConfirmed) {
          await this.deletePage(id)
        }
      })
    },
    async deletePage(id) {
      try {
        let userPID = parseInt(this.userInfo.pid) || ` `
        let OrgID = this.userOrganization.organizeID || ` `
        let type = localStorage.getItem('type')
        type = type === 'main' ? 'idp' : 'sandbox'
        let response = await Applications.deleteApplication(
          id,
          userPID,
          OrgID,
          type
        )
        if (response.status === 200) {
          Swal.fire({
            title: 'ลบแอปพลิเคชันสำเร็จ',
            icon: 'success'
          }).then(async () => {
            let data = {
              requestPid: Number(this.userInfo.pid),
              orgID: this.userOrganization.organizeID,
              ipAddress: ip.address(),
              detail: 'ลบ application ' + this.appData.applicationName
            }
            try {
              await General.postActivityLogs(data)
            } catch (err) {
              console.error(err)
            }

            this.$router.push('/home')
          })
        } else {
          Swal.fire({
            title: 'ลบแอปพลิเคชันไม่สำเร็จ',
            icon: 'error',
            text: response.data.info
          })
        }
      } catch (e) {
        Swal.fire({
          title: 'บันทึกไม่สำเร็จ',
          icon: 'error',
          text: this.errorHandler(e.response)
        })
      }
    },
    formatAddress(address) {
      let adds = ''
      try {
        adds = address.split('#').join(', ')
        return adds
      } catch {
        return address
      }
    },
    async getAppByID(id) {
      let type = localStorage.getItem('type')
      type = type === 'main' ? 'idp' : 'sandbox'
      const appData = await Applications.getApplicationByID(id, type)

      if (appData.data[0].organizeID == this.userOrganization.organizeID) {
        this.appData = appData.data[0]
        const { applicationScopeID, ...rest } = appData.data[0]
        let scope = applicationScopeID.split('#')
        this.appData = { scope, ...rest }
        let ipList = rest.ipAddress.split('#')
        this.model = ipList

        this.getDataConsent(id)
      } else {
        this.$router.push('/404')
      }

      let data = {
        requestPid: Number(this.userInfo.pid),
        orgID: this.userOrganization.organizeID,
        ipAddress: ip.address(),
        detail: 'ดูข้อมูล application ' + this.appData.applicationName
      }
      try {
        await General.postActivityLogs(data)
      } catch (err) {
        console.error(err)
      }
    },
    async getDataConsent(id) {
      this.dataConsentLoaded = false
      try {
        let type = localStorage.getItem('type')
        type = type === 'main' ? 'idp' : 'sandbox'
        const appConsent = await Applications.getApplicationConsent(id, type)
        const { approved, disapproved, pending, timeout } = appConsent.data
        this.approved = approved
        this.disapproved = disapproved
        this.timeout = timeout
        this.pending = pending
        this.dataConsentLoaded = true
      } catch (e) {
        // Swal.fire({
        //   title: 'ไม่สามารถเรียกข้อมูลการยินยอม',
        //   icon: 'error',
        //   text: this.errorHandler(e.response)
        // })
      }
    },
    async dialogConsent() {
      Swal.fire({
        title: 'แจ้งเตือน',
        icon: 'warning',
        text: 'หากสร้างคีย์ใหม่ คีย์เก่าจะไม่สามารถใช้งานได้',
        showCancelButton: true,
        confirmButtonText: 'ดำเนินการต่อ',
        cancelButtonText: 'ยกเลิก'
      }).then(async result => {
        if (result.isConfirmed) {
          this.checkPendingCredential()
        }
      })
    },
    async checkPendingCredential() {
      try {
        let type = localStorage.getItem('type')
        type = type === 'main' ? 'idp' : 'sandbox'
        let res = await Applications.getPendingCredential(
          this.appData.applicationID,
          type
        )

        if (res.status == 200) {
          try {
            let consent = await Applications.getConsentStatusByTxID(
              res.data.data.txID,
              type
            )
            if (consent.data.data.status == 'APPROVED') {
              this.showApplicationKey()
            } else {
              this.getConsentStatus(res.data.data.txID)
            }
          } catch (e) {
            Swal.fire({
              title: 'ไม่สามารถตรวจสอบสถานะคำขอ',
              icon: 'error',
              text: this.errorHandler(e.response)
            })
          }
        }
      } catch (e) {
        if (e.response.status == 404) {
          this.createApplicationKey()
        } else {
          Swal.fire({
            title: 'ไม่สามารถตรวจสอบรายการที่รอดำเนินการ',
            icon: 'error',
            text: this.errorHandler(e.response)
          })
        }
      }
    },
    async getConsentStatus(txID) {
      let timerInterval

      Swal.fire({
        title: 'กำลังรอการอนุมัติ',
        icon: 'info',
        text: 'กรุณายืนยันที่แอปพลิเคชัน ThaID',
        didOpen: () => {
          Swal.showLoading()

          timerInterval = setInterval(async () => {
            try {
              let type = localStorage.getItem('type')
              type = type === 'main' ? 'idp' : 'sandbox'
              let consent = await Applications.getConsentStatusByTxID(
                txID,
                type
              )
              if (consent.data.data.status == 'APPROVED') {
                clearInterval(timerInterval)
                Swal.close()
                this.showApplicationKey()
              } else if (consent.data.data.status == 'DISAPPROVED') {
                clearInterval(timerInterval)
                Swal.close()
                Swal.fire({
                  title: 'การอนุมัติถูกปฏิเสธ',
                  icon: 'error',
                  text: 'รายการถูกยกเลิกแล้ว'
                })
              }
            } catch (e) {
              Swal.fire({
                title: 'ไม่สามารถตรวจสอบสถานะคำขอ',
                icon: 'error',
                text: this.errorHandler(e.response)
              })
            }
          }, 5000)
        },
        willClose: () => {
          clearInterval(timerInterval)
        }
      })
    },
    async createApplicationKey() {
      try {
        let userPID = parseInt(this.userInfo.pid) || ` `
        let type = localStorage.getItem('type')
        type = type === 'main' ? 'idp' : 'sandbox'
        let res = await Applications.postCreateApplicationKey(
          this.appData.applicationID,
          userPID,
          type
        )

        if (res.status == 200) {
          this.getDataConsent(this.appID)
          Swal.fire({
            title: 'สร้างแอปพลิเคชันคีย์สำเร็จ',
            icon: 'success',
            text: 'กรุณายืนยันที่แอปพลิเคชัน ThaID',
            confirmButtonText: 'ตรวจสอบสถานะ'
          }).then(async result => {
            if (result.isConfirmed) {
              this.checkPendingCredential()
            }
          })
        }
      } catch (e) {
        if (e.response.status == 403 || this.pending != 0) {
          Swal.fire({
            title: 'สร้างแอปพลิเคชันคีย์ไม่สำเร็จ',
            icon: 'error',
            text: 'มีคำร้องรอการอนุมัติอยู่ กรุณาตรวจสอบที่แอปพลิเคชัน ThaID'
          })
        } else {
          Swal.fire({
            title: 'สร้างแอปพลิเคชันคีย์ไม่สำเร็จ',
            icon: 'error',
            text: this.errorHandler(e.response)
          })
        }
      }
    },
    async showApplicationKey() {
      let data = {
        requestPid: Number(this.userInfo.pid),
        orgID: this.userOrganization.organizeID,
        ipAddress: ip.address(),
        detail: 'สร้าง key application ' + this.appData.applicationName
      }
      try {
        await General.postActivityLogs(data)
      } catch (err) {
        console.error(err)
      }

      try {
        let type = localStorage.getItem('type')
        type = type === 'main' ? 'idp' : 'sandbox'
        let getSecret = await Applications.getApplicationKey(
          this.appData.applicationID,
          type
        )

        let keySecret = getSecret.data.data
        this.dialog = true
        this.endPoint = this.appData.callbackUrl
        this.secretKey = keySecret.apiKey
        this.clientKey = keySecret.clientSecret
        this.token = keySecret.basicToken
        this.clientId = keySecret.clientID
        this.getDataConsent(this.appData.applicationID)
      } catch (e) {
        Swal.fire({
          title: 'เรียกดูแอปพลิเคชันคีย์ไม่สำเร็จ',
          icon: 'error',
          text: this.errorHandler(e.response)
        })
      }
    },
    copyContent(id) {
      let itemTxt = document.querySelector('#' + id)
      itemTxt.setAttribute('type', 'text')
      itemTxt.select()
      itemTxt.setSelectionRange(0, 99999)

      try {
        document.execCommand('copy')
        this.snackbar = true
      } catch (err) {
        alert('คัดลอกไม่สำเร็จ!')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.border-b {
  margin-top: 0.2rem;
  margin-left: 0.7rem;
}

.border {
  border: 1px solid;
  border-radius: 4px;
}

.manage-app-btn {
  font-size: 16pt;
  font-weight: 400;
}

.row-wrap {
  margin-bottom: 1rem;

  &-text {
    background-color: #ffffff;
    margin: 0.2rem;
  }
}

.cursor-refresh {
  cursor: pointer;
}

.info-app-wrapper {
  height: 100%;
  width: 100%;
  padding: 2.4rem 1.6rem;
}

.card-proj {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #f1f1f1;

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
}

.card-proj-disable {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #f1f1f1;
}

.bg-graph {
  background: url(~@/assets/app-info-recolor.png) left bottom;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
}

.text-left {
  text-align: left;
}

.col-12 {
  padding: 5px 12px 0 12px;
}

.col-12 .scope {
  padding-left: 0;
}

.v-data-table::v-deep th {
  font-size: 1rem !important;
  font-weight: 400 !important;
  color: #f6f6fa !important;
  background: #3a3d80;
}

.v-data-table::v-deep tbody tr:nth-of-type(even) {
  background-color: #ecf0fe;
}

.v-data-table::v-deep .rounded-tl-lg {
  border-top-left-radius: 4px !important;
}

.v-data-table::v-deep .rounded-tr-lg {
  border-top-right-radius: 4px !important;
}

.rounded-b-lg {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.title-waiting-approve {
  font-size: 24pt;
  color: #4b4b4b;
}
</style>
